import * as React from "react";
import { Title } from "../../components/Typography";
import styled from "@emotion/styled";
import outreach from "../../assets/photos/outreach.jpg";
import { SideBySide } from "../../components/SideBySide";

const Question = styled.span(({ theme }) => ({
  display: "block",
  color: theme.colors.aqua,
  fontWeight: theme.fontWeights.bold,
  paddingBottom: theme.spacing[4],
}));

export const ProgramFAQs: React.FC = () => (
  <>
    <Title>Frequently asked questions</Title>
    <SideBySide imgSrc={outreach} imgAlt="board member talking to volunteer">
      <p>
        <Question>What areas do you serve?</Question>
        Visiting Neighbors serves seniors living in lower Manhattan south of
        30th Street, from river to river. We also have a friendly visiting
        program that serves seniors in the Forest Hills/Rego Park community of
        Queens.
      </p>
      <p>
        <Question>Is there a minimum age for seniors?</Question>
        Our services are available to anyone 60+ who is mentally alert,
        well-oriented and who is self-directed.
      </p>
      <p>
        <Question>How do seniors find out about you?</Question>
        Visiting Neighbors receives referrals, and reaches out through community
        organizations and events. Our reputation as a knowledgeable community
        resource is well established, because we have been serving seniors since
        1972.
      </p>
      <p>
        <Question>How much do you charge?</Question>
        There are no fees for our services. We are mandated to ask for a $5
        donation for each shop-and-escort errand, but this is a voluntary
        contribution. While every dollar helps, no one is turned away because
        they cannot pay.
      </p>
    </SideBySide>
    <p>
      <Question>What services do you provide?</Question>
      Our Manhattan program provides friendly visiting, help with errands and
      shopping, escorts to medical or other important appointments, health
      advocacy, telephone reassurance and information and referral to other
      services. If you have a question, we will do our best to get you an
      answer. Shop-and-escort assistance is not available in our Queens program,
      but we can refer you to other agencies that provide those services.
    </p>

    <p>
      <Question>How long does it take to become a client?</Question>
      Once you call and speak with a staff member, if you meet our criteria for
      service, we can usually schedule an intake interview within a month
      (sooner if scheduling availability permits).
    </p>

    <p>
      <Question>How often can someone visit me or help me?</Question>
      In our friendly visiting program, a volunteer visits you for a few hours
      once a week. Some volunteers are able to visit more often. For our
      shop-and-escort program, in order to be able to help everyone who calls
      us, we ask clients needing escorts or assistance with errands to limit
      their requests to once a week. In case of emergency, we will consider
      requests for additional assistance.
    </p>

    <p>
      <Question>
        How long can a volunteer stay with me? Will the volunteer wait for me at
        my doctor's office?
      </Question>
      A visit by a volunteer usually lasts for an hour or two. That is up to the
      senior and the volunteer. Our volunteers will generally wait with you at
      your doctor's office and bring you home, but if a lengthy appointment is
      involved, we may send someone to bring you to the appointment, and someone
      else to bring you home.
    </p>

    <p>
      <Question>Are the volunteers screened and trained?</Question>
      Visiting Neighbors carefully screens all volunteers, interviews everyone
      individually, and provides intensive training and orientation to our
      services. Every volunteer knows that ongoing support is always available
      if they have a question.
    </p>

    <p>
      <Question>What schools do the students come from?</Question>
      Our intergenerational program works with more than 100 high schools and
      colleges throughout New York City.
    </p>

    <p>
      <Question>Can I have the same volunteer every time?</Question>
      In our friendly visiting program, the same person will visit you every
      week so you can develop a relationship you both will enjoy. If you need
      assistance with shopping or errands, or an escort to an appointment, we
      will send whomever is available at that time. While we will try to
      accommodate requests for a specific shop-and-escort volunteer that is
      sometimes not possible.
    </p>

    <p>
      <Question>How do you get such wonderful volunteers?</Question>
      Visiting Neighbors works hard to find dedicated volunteers who enjoy
      helping others. We do a lot of outreach to the community. They know they
      can count on our staff to answer their questions and provide support if
      they need it. Many of our volunteers have been with VN for years.
    </p>
    <p>
      <Question>Where do you get your funding?</Question>
      Visiting Neighbors gets some support from individual donors, including
      voluntary contributions from our clients, friends and families of clients
      and volunteers, from foundations and corporations. We also receive some
      discretionary funding from our local legislators and local initiatives
      from our New York City Council. Some of our clients and donors have also
      left bequests for Visiting Neighbors. We also sponsor several fundraising
      activities each year to help cover the cost of our services. But we are
      always looking for new sources of support to help cover the cost of our
      services and would certainly welcome any suggestions.
    </p>
    <p>
      <Question>Can you send someone to help my mother?</Question>
      We ask that anyone who wants a volunteer visitor call us personally.
      Sometimes, a well-meaning relative, friend or neighbor thinks that a
      senior would like a visitor, but the senior does not actually want one, so
      we ask that the senior personally make the request. We want to begin a
      supportive relationship with a senior by empowering them, and by helping
      them to maintain control over their life.
    </p>
    <p>
      <Question>I'm a social worker. Can I refer a client?</Question>
      We receive referrals from many health and social service professionals. We
      always inform them that the senior must request our help. We will work
      with the referring professional to keep them informed of our involvement.
      Anyone can help a senior make a call to us, give us some basic information
      and then put the potential client on the phone so we can speak with them
      directly.
    </p>
    <p>
      <Question>
        Is the information that I share with Visiting Neighbors kept private?
      </Question>
      Yes, all information we gather on volunteers and seniors is kept
      confidential. We do not sell our mailing list.
    </p>
  </>
);
