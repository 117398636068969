import * as React from "react";
import styled from "@emotion/styled";
import { defaultTheme } from "../helpers/theming";
import { useHistory } from "react-router-dom";

type LinkProps = {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  variation?: "dark" | "light" | "highlighted";
  openInNewWindow?: boolean;
  ariaLabel?: string;
  tabIndex?: number;
  href?: string;
  onClick?: VoidFunction;
};

const colors = {
  dark: defaultTheme.colors.black,
  light: defaultTheme.colors.white,
  highlighted: defaultTheme.colors.aqua,
};

const focusColors = {
  dark: defaultTheme.colors.red,
  light: defaultTheme.colors.gray,
  highlighted: defaultTheme.colors.red,
};

const StyledLink = styled.a<Pick<LinkProps, "disabled" | "variation">>(
  ({ disabled, variation = "dark" }) => [
    {
      fontWeight: 600,
      color: colors[variation],
      cursor: "pointer",
      textDecoration: "none !important",
    },
    disabled && {
      cursor: "not-allowed",
    },
  ]
);

const StyledButton = styled.button<LinkProps>(
  ({ disabled, variation = "dark", theme }) => [
    {
      fontWeight: 600,
      color: colors[variation],
      background: "none",
      border: "none",
      cursor: "pointer",
      "&:focus": {
        outline: "none",
        boxShadow: `0 0 0 2px ${focusColors[variation]}`,
        borderRadius: theme.borders.radius.xlarge,
      },
      "&:focus-visible": {
        outline: "none",
        boxShadow: `0 0 0 2px ${focusColors[variation]}`,
        borderRadius: theme.borders.radius.xlarge,
      },
      "&:focus:not(:focus-visible)": {
        outline: "none",
        boxShadow: "none",
      },
    },
    disabled && {
      cursor: "not-allowed",
    },
  ]
);

export const Link: React.FC<LinkProps> = ({
  disabled,
  loading,
  children,
  href,
  variation = "dark",
  openInNewWindow,
  ariaLabel,
  tabIndex: tabIndexProp,
  onClick,
}) => {
  const isExternal =
    !href || href.indexOf("http://") === 0 || href.indexOf("https://") === 0;
  const isAsset = href && href.indexOf("assets/") > -1;
  const anchorProps = isExternal
    ? {
        rel: "noopener noreferrer",
        target: "_blank",
      }
    : { target: openInNewWindow ? "_blank" : undefined };
  const tabIndex = disabled
    ? { tabIndex: -1 }
    : { tabIndex: tabIndexProp ?? 0 };

  const history = useHistory();
  const handleInternalAnchorClick = React.useCallback(
    (event) => {
      event.preventDefault();
      onClick?.();
      if (openInNewWindow) {
        window.open(href);
      } else {
        history.push(href ?? "");
      }
    },
    [href, history, openInNewWindow, onClick]
  );

  if (href) {
    return (
      <StyledLink
        {...anchorProps}
        {...tabIndex}
        href={href}
        variation={variation}
        disabled={disabled || loading}
        onClick={
          !isAsset && !isExternal ? handleInternalAnchorClick : undefined
        }
        {...{ "aria-label": ariaLabel }}
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      {...tabIndex}
      onClick={onClick}
      variation={variation}
      disabled={disabled || loading}
      {...{ "aria-label": ariaLabel }}
    >
      {children}
    </StyledButton>
  );
};
