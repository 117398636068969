import * as React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { IconProps } from "./Icons";

const spinningAnimation = keyframes({
  "0%": { transform: "rotate(0)" },
  "100%": { transform: "rotate(360deg)" },
});

type SpinnerProps = {
  size: IconProps["size"];
};

const viewboxSize = 16;
const strokeWidth = 2;

const diameter = viewboxSize - strokeWidth;
const radius = diameter / 2;
const circumference = diameter * Math.PI;
const strokeLength = circumference - circumference * 0.25;

const StyledSpinner = styled.div<SpinnerProps>(({ size, theme }) => ({
  animation: `${spinningAnimation} 1s infinite`,
  position: "relative",
  width: size,
  height: size,
}));

const Stroke = styled.svg(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  fill: "none",
  strokeDasharray: circumference,
  strokeDashoffset: strokeLength,
  transition: `stroke-dashoffset ${theme.animations.standard} linear`,
  stroke: "currentColor",
}));

export const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  return (
    <StyledSpinner size={size}>
      <Stroke
        width="100%"
        height="100%"
        viewBox={`0 0 ${viewboxSize} ${viewboxSize}`}
      >
        <circle
          cx={viewboxSize / 2}
          cy={viewboxSize / 2}
          r={radius}
          strokeWidth={strokeWidth}
          transform={`rotate(-90 ${viewboxSize / 2} ${viewboxSize / 2})`}
        />
      </Stroke>
    </StyledSpinner>
  );
};
