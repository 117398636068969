import { Link } from "../../../components/Link";
import { urls } from "../../../helpers/urls";

import artHeart from "../../../assets/photos/artHeart.jpg";
import barnDance from "../../../assets/photos/barnDance.jpg";
import boat from "../../../assets/photos/boat.jpg";
import cabaret from "../../../assets/photos/cabaret.jpg";
import covid from "../../../assets/photos/covid.jpg";
import crabFest1982 from "../../../assets/photos/crabFest1982.jpg";
import crabFest1998 from "../../../assets/photos/crabFest1998.jpg";
import crabfest from "../../../assets/photos/crabfest.jpg";
import csa from "../../../assets/photos/communityServiceAward.jpg";
import cuts from "../../../assets/photos/cuts.jpg";
import cynthia from "../../../assets/photos/cynthia.jpg";
import danny from "../../../assets/photos/danny.jpg";
import dec from "../../../assets/photos/dec.jpg";
import elfing from "../../../assets/photos/elfing.jpg";
import elfing2021 from "../../../assets/photos/elfing2021.jpg";
import extended from "../../../assets/photos/extended.jpg";
import fieldTrip from "../../../assets/photos/fieldTrip.jpg";
import flowers from "../../../assets/photos/flowers.jpg";
import founders from "../../../assets/photos/founders.jpg";
import gala from "../../../assets/photos/gala.jpg";
import health from "../../../assets/photos/health10.jpg";
import incorporation from "../../../assets/photos/incorporation.jpg";
import intergenerational from "../../../assets/photos/intergenerational.jpg";
import logos from "../../../assets/photos/logos.jpg";
import mission from "../../../assets/photos/mission.jpg";
import newYorkNeighbors from "../../../assets/photos/newYorkNeighbors.jpg";
import noPlaceLikeHome from "../../../assets/photos/noPlaceLikeHome.jpg";
import nursingProgram from "../../../assets/photos/nursingProgram.jpg";
import nursingProgram2 from "../../../assets/photos/nursingProgram2.jpg";
import nursingProgram3 from "../../../assets/photos/nursingProgram3.jpg";
import proc1982 from "../../../assets/photos/proc1982.jpg";
import proc1993 from "../../../assets/photos/proc1993.jpg";
import proc2009 from "../../../assets/photos/proc2009.jpg";
import proc2010 from "../../../assets/photos/proc2010.jpg";
import proc2014 from "../../../assets/photos/proc2014.jpg";
import ribbon from "../../../assets/photos/ribbon.jpg";
import roosevelt from "../../../assets/photos/roosevelt.jpg";
import shopEscort from "../../../assets/photos/shopEscort.jpg";
import talentShow1995 from "../../../assets/photos/talentShow1995.jpg";
import talentShow2015 from "../../../assets/photos/talentShow2015.jpg";
import talentShow2022 from "../../../assets/photos/talentShow2022.jpg";
import talentShow2023 from "../../../assets/photos/talentShow2023.jpg";
import talentShow9 from "../../../assets/photos/talentShow9.jpg";
import thanksgiving from "../../../assets/photos/thanksgiving.jpg";
import valentines from "../../../assets/photos/valentines.jpg";
import valentines2024 from "../../../assets/photos/valentines2024.jpeg";
import vid2 from "../../../assets/photos/vid2.jpg";
import volunteerCoordinator from "../../../assets/photos/volunteerCoordinator.jpg";
import waving from "../../../assets/photos/waving.jpg";
import wrapping from "../../../assets/photos/wrapping.jpg";
import knitting from "../../../assets/photos/knitting.jpg";
import talentShow30 from "../../../assets/photos/talentShow30.jpg";

export type EventProps = {
  title: string;
  description: React.ReactNode;
  year: string;
  image: string;
  imagePosition?: string;
};

export const timelineContent: EventProps[] = [
  {
    title: "The Beginning",
    year: "Spring 1972",
    description:
      "Eight individuals concerned about seniors in their community get together to start a Friendly Visiting Program. ictured here is our 1st Board President, Janet Abels and one of our founding Board members, John Geer, Sr., who also served for many years as our legal counsel.",
    image: founders,
  },
  {
    title: "1st Volunteer Coordinator Hired",
    year: "Summer 1972",
    description:
      "The group hires its first Volunteer Coordinator, Lucille Strider and has its first Street Fair in the Village.",
    image: volunteerCoordinator,
  },
  {
    title: "Incorporation",
    year: "1974",
    description:
      "Village Visiting Neighbors becomes Incorporated. (Original 501(c)(3))",
    image: incorporation,
  },
  {
    title: "Expanded Services",
    year: "1975",
    description:
      "Visiting Neighbors soon offers two services: Shop & Escort and Friendly Visiting",
    image: shopEscort,
    imagePosition: "center 33%",
  },
  {
    title: "Proclamation — 10th Anniversary",
    year: "1982",
    description:
      "Visiting Neighbors receives a Proclamation for its 10th Anniversary from the Office of Mayor Ed Koch.",
    image: proc1982,
    imagePosition: "center 15%",
  },
  {
    title: "The Annual Crab & Beer Fest",
    year: "October 1982",
    description:
      "It's a Finger Lickin' Time at the first of many of Visiting Neighbors' most popular event, the Annual Crab & Beer Fest.",
    image: crabfest,
    imagePosition: "center 10%",
  },
  {
    title: "Extended Reach",
    year: "1984",
    description:
      "Visiting Neighbors extends its reach to develop satellites in East Village, Gramercy, Chelsea, Midtown East, Clinton. In Queens: Forest Hill, Rego Park, Maspeth, and Middle Village.",
    image: extended,
    imagePosition: "center 20%",
  },
  {
    title: "Flower Show",
    year: "June 1984",
    description: "Flower Show at P.S. 41, making front page NY Times Coverage",
    image: flowers,
    imagePosition: "center 33%",
  },
  {
    title: "Eleanor Roosevelt Community Service Award",
    year: "December 1984",
    description:
      "Village Visiting Neighbors was chosen out of a list of 500 nominees and awarded the 1st NY State Annual Eleanor Roosevelt Community Service Award, presented by Gov. Cuomo's wife, Matilda.",
    image: roosevelt,
  },
  {
    title: "Boat Ride Fundraiser",
    year: "Summer 1985",
    description:
      "Circle Line Boat Ride Fundraiser. A popular event that failed only because it rained all 3 years (3 strikes, no more boat rides).",
    image: boat,
    imagePosition: "center 70%",
  },
  {
    title: "Village Barn Dance",
    year: "Fall 1985",
    description:
      "The Village Barn Dance, held in the basement of Our Lady Pompeii Church with music by Tony Trischka, Banjo virtuoso.",
    image: barnDance,
    imagePosition: "cover 100%",
  },
  {
    title: "4th Crab & Beer Fest",
    year: "1985",
    description:
      "Visiting Neighbors holds its first of many successful, well-attended New Orleans Crab Fests. It was so highly requested that we did it again for the third time in 1985.",
    image: crabFest1982,
  },
  {
    title: "15th Anniversary Gala",
    year: "February 1988",
    description:
      "Village Gate 15th Anniversary Gala - “After 15 years of staying at home, we thought we deserved a night out!” Performers included were from the Cotton Club, Ziegfeld Girls, the Met, and Broadway.",
    image: gala,
    imagePosition: "center 20%",
  },
  {
    title: "Our Mission",
    year: "1990",
    description: (
      <>
        Our mission is formalized, and we start providing additional services.
        See{" "}
        <Link href={urls.about.main} variation="highlighted">
          Our Mission
        </Link>
      </>
    ),
    image: mission,
  },
  {
    title: "Holiday Elfing",
    year: "December 1991",
    description:
      "Elfing project first realized Seniors have more accidents during the holidays. They were lonely and depressed. After the first year of elfing, accidents leveled off to same as the rest of the year.",
    image: elfing,
  },
  {
    title: "Proclamation — Volunteer Dedication",
    year: "1993",
    description:
      "Visiting Neighbors receives a Proclamation for its Volunteer Dedication from the Council City of New York, by Kathryn Freed, Council Member, 1st District - Manhattan.",
    image: proc1993,
    imagePosition: "center 10%",
  },
  {
    title: "Intergenerational Work/Study Program begins",
    year: "July 1994",
    description:
      "Inner City Students (ages 13 to 21) who needed extra help in school, volunteered to help seniors with shopping and escorting to medical appointments. The students learned office skills and were given encouragement and sensitivity training from staff and tutoring from clients. Visiting Neighbors worked with a hundred young people each year from all backgrounds and walks of life, encouraging them to stay in school and go on to college. This was one of our city's most successful programs until the city cut funding for all of our city's Intergenerational programs in January 2011.",
    image: intergenerational,
    imagePosition: "center 100%",
  },
  {
    title: "1st Senior Talent Show",
    year: "April 1995",
    description:
      "The 1st Annual Senior Talent Show -- with 24 to follow -- begins, featuring city wide contestants.",
    image: talentShow1995,
  },
  {
    title: 'Annual "Elfing" Gift Wrapping party',
    year: "November 1996",
    description: (
      <>
        VN holds it's Annual "Elfing" Gift Wrapping party. This was the year
        volunteers got so into it that accidentally anything laying around,
        including volunteer's personal items ie. lipstick, umbrella etc., got
        wrapped. Luckily, the items were easily found by virtue of their
        different shape.
      </>
    ),
    image: wrapping,
    imagePosition: "0% 0%",
  },
  {
    title: "Governor's Community Service Award",
    year: "1998",
    description:
      "Visiting Neighbors is honored by Governor's Community Service Award, presented by Governor Pataki's wife, Libby.",
    image: csa,
    imagePosition: "0% 25%",
  },
  {
    title: "Visiting Neighbors, Inc.",
    year: "1998",
    description:
      "Village Visiting Neighbors becomes Visiting Neighbors, Inc. Serving Lower Manhattan. All Satellites that remained independent came under Visiting Neighbors umbrella.",
    image: logos,
    imagePosition: "center 0%",
  },
  {
    title: 'Visiting Neighbors Hails "Volunteer of the Year"',
    year: "Summer 1998",
    description:
      "Each year we recognize our volunteers who are at the heart of everything we do.",
    image: newYorkNeighbors,
    imagePosition: "center 0%",
  },
  {
    title: "16th Crab & Beer Fest",
    year: "October 1998",
    description:
      "Guests had fun nominating for their choice for both Hot Queen Crab and Cool King Crab.",
    image: crabFest1998,
    imagePosition: "0% 0%",
  },
  {
    title: "Annual Cabaret",
    year: "2001",
    description:
      "Visiting Neighbors had a successful run of Three Annual Cabaret Events where Broadway stars donated their talents including Julie Gold, Julie Wilson, and Baby Jane Dexter.",
    image: cabaret,
    imagePosition: "center 45%",
  },
  {
    title: "No Place Like Home!",
    year: "2004",
    description:
      "“No Place Like Home” carnival event, while exciting, occurred on the evening of one of the worst and unexpected summer storms of the decade.",
    image: noPlaceLikeHome,
  },
  {
    title: "Field Trips",
    year: "2006",
    description:
      "Visiting Neighbors takes seniors on the 1st of many field trips each summer.",
    image: fieldTrip,
  },
  {
    title: "Art from the Heart",
    year: "2008",
    description:
      "As a result of the sudden recession, Visiting Neighbors staff & volunteers were determined to help our doors open.  Seniors let up know they needed us and were scared. Seniors & and older volunteers who were artists donated some of their creations to raise immediate money and the first Art From the Heart fundraiser was born.",
    image: artHeart,
  },
  {
    title:
      "Proclamation — Visiting Neighbors Centenarian Club Appreciation Day",
    year: "2009",
    description:
      "Visiting Neighbors receives a Proclamation for its Centenarian Club Appreciation Day from the Office of the Manhattan Borough President, Scott M. Stringer.",
    image: proc2009,
    imagePosition: "center 0%",
  },
  {
    title: "The Brooke Russel Astor Award",
    year: "2009",
    description: (
      <>
        Oct 27th 2009 Visiting Neighbors' Executive Director receives The Brooke
        Russel Astor Award presented at The New York Public Library "for her
        tireless dedication to making a fundamental difference in the lives of
        New Yorkers". Thank you to our Assembly Member, Hon. Deborah Glick for
        the nomination.
      </>
    ),
    image: cynthia,
    imagePosition: "center 0%",
  },
  {
    title: "Proclamation — Volunteer Recognition",
    year: "2010",
    description:
      "Visiting Neighbors receives a Proclamation for Volunteer Recognition from the Council City of New York, by Christine Quinn, Speaker and Rosie Mendez, Council Member of the 2nd District - Manhattan and Jessica Lappin, Council Member of the 5th District - Manhattan.",
    image: proc2010,
    imagePosition: "center 0%",
  },
  {
    title: "DFTA and DYCD Funding Cuts",
    year: "December 2010",
    description:
      "At the end of December, Visiting Neighbors was in the middle of contracts, when we were notified that the city had to make cuts and we lost all DFTA and DYCD funding regardless of our outstanding record and services. VN downsized and through a lot of good will from a lot of great people, including a former board member who gave up space in his 200 sq. ft closet. Staff donated their time for 9 months and where unemployed and determined to continue to help seniors who had nowhere to go. Because of a few great people we are still here today.",
    image: cuts,
    imagePosition: "center 10%",
  },
  {
    title: "3 Washington Square Village",
    year: "November 2013",
    description:
      "Moved to current home: 3 Washington Square Village. We were thrilled to have room to move around, interview volunteers, invite seniors and guests, and here we have windows.",
    image: ribbon,
  },
  {
    title: "Student Nursing & Wellness Program",
    year: "2014",
    description:
      "Begin a Collaboration with NYU Rory Meyers College of Nursing: the Student Nursing & Wellness Program. Student nurses from the NYU Rory Meyers College of Nursing, under the guidance of their professor and Visiting Neighbors' staff, serve as additional eyes and ears for staff and offer increased support to our clients.",
    image: nursingProgram,
    imagePosition: "center 25%",
  },
  {
    title: "Proclamation — Visiting Neighbors Senior Talent Show",
    year: "2014 - 2019 (multiple years)",
    description:
      "Visiting Neighbors receives a Proclamation for its Visiting Neighbors Senior Talent Show from the Office of the Manhattan Borough President, Gale A. Brewer",
    image: proc2014,
    imagePosition: "center 0%",
  },
  {
    title: "21st Annual Senior Talent Show",
    year: "September 2015",
    description: "21st Annual Senior Talent Show at the Chelsea Day Festival",
    image: talentShow2015,
    imagePosition: "center 10%",
  },
  {
    title: "BAMRA/LeSouk Annual Thanksgiving Dinner",
    year: "2016",
    description:
      "For several years, LeSouk prepared a free dinner, BAMRA matched all donations to Visiting Neighbors at Thanksgiving, and we're very grateful!",
    image: thanksgiving,
    imagePosition: "0% 25%",
  },
  {
    title: "Annual Senior Talent Shows - MC Extraordinaire",
    year: "April 2019",
    description: (
      <>
        A BIG HUG TO Danny Morris who served for a decade as our "MC
        Extraordinaire" for our Annual Senior Talent Show.
      </>
    ),
    image: danny,
    imagePosition: "0% 20%",
  },
  {
    title: "Nursing student presentations",
    year: "February 2020",
    description:
      "Our seniors enjoy and learn from our nursing students' last large presentation just before the pandemic hit.",
    image: nursingProgram2,
    imagePosition: "0% 10%",
  },
  {
    title: "Help During COVID-19",
    year: "March 2020",
    description:
      "Pandemic Hits New York City & Visiting Neighbors continues its unwavering service to seniors. We became creative and just did it in a different way while following safety protocols. “We were here in our offices while others worked remotely. We responded to every call we received.”",
    image: covid,
    imagePosition: "0% 10%",
  },
  {
    title: "Village Independent Democrats Award",
    year: "2021",
    description:
      "Visiting Neighbors receives Village Independent Democrats Award recognition for our work helping seniors during COVID-19. The award was presented at VID's 64th Annual Awards Ceremony by Tony Hoffmann and the VID club co-presidents, Mar Fitzgerald and Cam Krause, who were the emcees for the evening.",
    image: vid2,
  },
  {
    title: "Community Medicine continues",
    year: "Fall 2021",
    description:
      "VN continues its Community Medicine collaboration with Mercy Collage Of Nursing.",
    image: health,
    imagePosition: "0% 40%",
  },
  {
    title: "Honoring a longstanding volunteer",
    year: "November 2021",
    image: waving,
    description:
      "VN honored our longstanding volunteer, Daniel Farmer (pictured on the right with his proud Mom beside him), for providing over 100 hours of volunteer service last year during the height of COVID.",
    imagePosition: "0% 20%",
  },
  {
    title: "Holiday Elfing",
    year: "December 2021",
    description:
      "Our Elfing project has begun. Interested in volunteering during December and/or January delivering holiday gift packages to seniors? Give us a call.",
    image: elfing2021,
    imagePosition: "0% 0%",
  },
  {
    title: "28th Senior Talent Show",
    year: "April 2022",
    description:
      "The day was a big success! There was great energy all around us as our seniors are quite spirited and showcased that talent is ageless!!",
    image: talentShow2022,
    imagePosition: "0% 12%",
  },
  {
    title: "I'm Still Here - Seniors & Volunteers Creating Generativity",
    year: "December 2022",
    description: (
      <>
        Thanks to <i>The Sephardic Foundation on Aging</i>, we launched a one
        year project,{" "}
        <i>"I'm Still Here - Seniors & Volunteers Creating Generativity."</i>{" "}
        Volunteers, ages 14-83, spend quality time visiting with seniors in
        their homes. The seniors enjoy sharing stories of their remarkable lives
        with enthusiastic listeners.
      </>
    ),
    image: dec,
  },
  {
    title: "Delightful valentines",
    year: "February 2023",
    description:
      "Second graders & fourth graders hand made Valentines Day cards for our seniors, who were delighted to get them.",
    image: valentines,
  },
  {
    title: "29th Senior Talent Show",
    year: "September 2023",
    description: (
      <>
        Visiting Neighbors celebrated their 29th <i>Senior Talent Show</i>{" "}
        during the Chelsea Day Festival showcasing the vitality and talent of
        our seniors. We enjoyed plenty of sunshine, positive energy and happy
        spirits! Our first place winners - <i>The Phoenix Dance Team</i> -
        charmed the judges and audience with their graceful and well-coordinated
        moves, but as far as we are concerned - all the performers are winners!
      </>
    ),
    image: talentShow2023,
  },
  {
    title: "Senior Resource Fair",
    year: "October 2023",
    description: (
      <>
        Visiting Neighbors partnered with Mercy University School of Nursing
        Seniors, joined by their professor to offer Blood Pressure/Heart Rate
        screenings at{" "}
        <b>State Senator Brad Hoylman-Sigal’s Senior Resource Fair</b> on
        October 24th. Hundreds of Seniors attended the Fair and were eager to
        have their BP/Pulse checked by one of our student nurses.
      </>
    ),
    image: nursingProgram3,
  },
  {
    year: "December 2023",
    title: "Our Naughty Knitters",
    description:
      "Every year a talented and dedicated group of volunteers from NYU hand-knit scarves and hats for us to be included in seniors' goodie bags distributed by our elves for the holidays.",
    image: knitting,
  },
  {
    year: "February 2024",
    title: "Valentine's Day 2024",
    description:
      "Each year local school children make Valentine's cards for our seniors to add some cheer. The seniors tell us they love them!",
    image: valentines2024,
  },
  {
    year: "April 2024",
    title: "30th Senior Talent Show",
    description:
      "We welcomed back The Jazzy Dancers as our opening act, and we were so excited! While the rain threatened to come, we didn't feel a single drop until after our show was over. Mother Nature was smiling down on our seniors, and the huge crowd cheered everyone on!",
    image: talentShow30,
    imagePosition: "0% 20%",
  },
  {
    year: "Today",
    image: talentShow9,
    imagePosition: "0% 15%",
    title: "Time flies...",
    description: (
      <>
        We are still here doing what we do best - helping seniors thanks to a
        dedicated staff, loving volunteers, caring board members, amazing
        seniors, generous donors, kind friends and thoughtful neighbors.{" "}
        <p>
          Thank you to all those who have been part of our story, some gone now,
          yet never forgotten; including former spokespersons Katharine Hepburn,
          Kim Hunter, Christopher Reeve, and Jessica Tandy.
        </p>
        <p>
          <b>Thanks to all of you for continuing to make a difference!</b>
        </p>
      </>
    ),
  },
];
