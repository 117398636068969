export const volunteerQuotes = [
  {
    quote:
      'Volunteering with my senior morphed into a "family experience." It was not volunteering per se, but the art of truly caring about each other as humans, seeing beyond our differences, and extending a whole lot of love. Love is the highest service you could ever provide to another.',
    person: "Taneeka W.",
  },

  {
    quote:
      "It make my evening when I go over to see my senior after a rough day at work and her face lights up when I walk through the door.",
    person: "Moishe M.",
  },

  {
    quote:
      "It make me feel good knowing I make a difference and can help someone.",
    person: "Ken W.",
  },

  {
    quote:
      "At first I though it was the senior who would be benefiting from my visits, but after time I learned that I get back just as much.....if not more.",
    person: "Sarah M.",
  },

  {
    quote:
      "I love hearing stories about what New York was like many years ago.",
    person: "Raj  C.",
  },

  {
    quote:
      "Never have I felt so appreciated as  when I volunteered with Visiting Neighbors.",
    person: "Isha I.",
  },

  {
    quote:
      "It has been a real joy meeting with new people.  It is a pleasure to be able to help them.  It gives me a joy to give back and for that reason I am blessed.",
    person: "Nicki C.",
  },

  {
    quote:
      "Words can't describe what Visiting Neighbors has meant to me.  One of the luckiest days of my life was when I found such a wonderful organization. ",
    person: "Jessie K.",
  },

  {
    quote:
      "Just spending time with a senior who values my company makes me day.",
    person: "Daniel F.",
  },

  {
    quote:
      "Helping seniors is a fun and adventurous experience. I have learned so much from them and got a lot of great practical advice.  I also learned a lot about myself.",
    person: "Isaac C.",
  },

  {
    quote:
      "Volunteering for Visiting Neighbors has been an extremely wonderful experience for me.  It fills my heart with joy.",
    person: "Lakshika D.",
  },

  {
    quote:
      "The volunteer experience has meant a great deal to me.  I have made long-lasting relationships with several seniors and the staff.",
    person: "Patty A.",
  },

  {
    quote: "Volunteering with Visiting Neighbors has taught me a great deal.",
    person: "Sue W.",
  },

  {
    quote:
      "I never realized the obstacles that the senior population has to overcome. I am glad I can help to alleviate some of those problems.",
    person: "Jerome F.",
  },
];
