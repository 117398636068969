import * as React from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router";
import { parse } from "qs";

const Image = styled.img({
  width: "100%",
  // fix for IE https://stackoverflow.com/a/33505912/605511
  minHeight: 1,
  height: "auto",
});

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  padding: theme.spacing[32],
}));

export const ViewImage: React.FC = () => {
  const { search } = useLocation();
  const queryParams = React.useMemo(
    () => parse(search, { ignoreQueryPrefix: true }),
    [search]
  ) as { [key: string]: string };
  return (
    <Wrapper>
      <Image src={queryParams.src} alt="" />
    </Wrapper>
  );
};
