export const styling = {
  animations: { standard: "100ms" },
  colors: {
    aqua: "#1380A6",
    red: "#cd2b31",
    yellow: "#f5d90a",
    gray: "#ECECEC",
    gray100: "#D0D0D0",
    gray200: "#a0a0a0",
    gray300: "#505050",
    white: "#FFFFFF",
    black: "#171717",
  },
  shadows: {
    pageContentShadow: `2px 2px 12px rgba(0,0,0,0.25)`,
    hoverShadow: `0 0 25px rgba(0,0,0,0.05)`,
    menuDropShadow: `0 4px 24px rgba(0,0,0,0.15)`,
    cardShadow: `0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)`,
  },
  spacing: {
    2: 2,
    4: 4,
    8: 8,
    12: 12,
    16: 16,
    20: 20,
    24: 24,
    28: 28,
    32: 32,
    36: 36,
    40: 40,
    44: 44,
    48: 48,
    56: 56,
    60: 60,
  },
  zIndex: {
    base: 0,
    bump: 100,
    overlay: 4000,
    modalMask: 6999,
    modal: 7000,
    popover: 8000,
    tooltip: 9000,
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  borders: {
    radius: {
      default: 10,
      xsmall: 2,
      small: 4,
      large: 16,
      xlarge: 20,
    },
  },
};
