import * as React from "react";
import { Title } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";
import cards2 from "../../assets/photos/cards2.jpg";
import specialOccasions from "../../assets/photos/specialOccasions.jpg";
import specialOccasions2 from "../../assets/photos/specialOccasions2.jpg";
import { Button } from "../../components/Button";
import { urls } from "../../helpers/urls";

export const SpecialOccasions: React.FC = () => (
  <>
    <Title>Remembering special occasions</Title>
    <SideBySide imgSrc={cards2} imgAlt="Valentines cards" hasExtraSpace>
      <b>We remember and celebrate special occasions!</b>. Local school children
      handmake personalized birthday cards, Valentines, Mother’s, and Father’s
      Day cards. Some seniors become pen pals with some of the students. In the
      month of December, we have volunteers put together care packages of
      donated items, which can often be a time that is especially lonely for
      seniors who otherwise have no one to share in the joys of the season. Our
      volunteer “Elves” remind them that they are not alone and share holiday
      cheer.
    </SideBySide>
    <SideBySide
      imgSrc={specialOccasions2}
      imgAlt="Special occasion"
      reverse
      hasExtraSpace
    >
      We send out birthday and cards of encouragement which the seniors
      expressed being very happy to receive. Each Spring, we have several grade
      school teachers work with their students to make Happy Spring Cards with
      positive messages of wishes for good cheer, happiness and hope. The
      seniors love them and many call us after receiving them and/or write back
      to us sharing how the cards bring some brightness into their day. Regular
      communication with our clients continues to be our priority, with multiple
      mailings, such as, birthday cards, cheer-up and “hang-in there” notes,
      sympathy cards, seasonal newsletters.
    </SideBySide>
    <SideBySide
      imgSrc={specialOccasions}
      imgAlt="Special occasion"
      hasExtraSpace
    >
      We also make condolences calls, but our message is always one of “someone
      does care about you.” We are creative in our efforts to communicate both
      important and uplifting mailings, including sending inspirational poems,
      word games and puzzles, stress relieving tips, at-home exercise using a
      chair and household items, easy recipes and messages of hope.
      <p>
        <Button href={urls.contact}>Contact us</Button>
      </p>
    </SideBySide>
  </>
);
