export enum ViewportSizes {
  Desktop = 1800,
  Phone = 600,
  SmallDesktop = 960,
  TabletLandscape = 1200,
  TabletPortrait = 900,
}

function generateMediaQueryString(viewportSize: ViewportSizes): string {
  return `@media (max-width: ${viewportSize}px)`;
}

export const breakpoints = {
  desktop: generateMediaQueryString(ViewportSizes.Desktop),
  phone: generateMediaQueryString(ViewportSizes.Phone),
  smallDesktop: generateMediaQueryString(ViewportSizes.SmallDesktop),
  tabletLandscape: generateMediaQueryString(ViewportSizes.TabletLandscape),
  tabletPortrait: generateMediaQueryString(ViewportSizes.TabletPortrait),
};
