import * as React from "react";
import { Title } from "../../components/Typography";
import ReferenceForm from "../../assets/ReferenceForm.pdf";
import VolunteerApplicationShort from "../../assets/VolunteerApplicationShort.pdf";
import VolunteerApplication from "../../assets/VolunteerApplication.pdf";
import { email, phone } from "../../helpers/constants";
import { File } from "../../components/File";

const files = [
  {
    name: "Volunteer application",
    file: VolunteerApplication,
  },
  {
    name: "Volunteer application (special events / short term projects)",
    file: VolunteerApplicationShort,
  },
  { name: "Reference form", file: ReferenceForm },
];

export const Applications: React.FC = () => {
  return (
    <>
      <Title>Volunteer application</Title>
      <p>
        For new volunteers, send completed applications and reference forms to
        Howie Square at <b>{email}</b>
      </p>
      <p>
        As an interview is required, you can expedite the process by completing
        your application, obtaining two completed letters of reference, and
        calling Howie Square at <b>{phone}</b> to tell him you have all of your
        paperwork prepared.
      </p>
      <p>If you have any questions, feel free to give us a call!</p>
      <p>
        {files.map(({ name, file }) => (
          <p>
            <File href={file} name={name} />
          </p>
        ))}
      </p>
    </>
  );
};
