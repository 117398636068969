import * as React from "react";
import { Title } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";
import whoWeServe from "../../assets/photos/whoWeServe.jpg";
import { WeServe } from "../../components/WeServe";

export const WhoWeServe: React.FC = () => {
  return (
    <>
      <Title>Who we serve</Title>
      <WeServe />
      <SideBySide imgAlt="Senior and volunteer" imgSrc={whoWeServe} align="top">
        Visiting Neighbors’ clients are age 60 and over. Currently our eldest
        client is 105 years of age. The average age is 89. Clients tend to be
        frail, with one or more ailments. We serve a diverse group of
        individuals, most of whom live on fixed incomes and can’t afford to pay
        for help. There are no income eligibility requirements, nor a fee for
        services.
        <p>
          All of our clients are able to self-direct. However, individual
          personalities vary, with a few who are a bit offbeat or eccentric. The
          senior who comes to us usually lives alone and has little or no family
          nearby that they can turn to for support. They are often isolated,
          nervous about crossing streets and getting to and from their
          appointments safely. Many have health concerns, have poor
          communication with their doctors and need help with errands. Our
          volunteers accompany clients taking them outside for fresh air, to
          socialize or take a walk.
        </p>
        <p>
          Our seniors face a myriad of health concerns including loneliness,
          anxiety, mobility issues. They also face financial struggles and
          losses of all kinds including family, friends, pets, abilities, income
          and their pride. Some individuals do not take care of themselves,
          dismiss their physical problems, ineffectively articulate their needs
          and/or are fearful of many things including falling, learning about a
          potential diagnosis, having to cope with more loss in the near future
          and judgement from others. Many seniors do not feel comfortable asking
          for help. Visiting Neighbors’ seniors feel more confident knowing we
          are here to listen, offer support and guidance. We earn their trust.
          They understand that we do not judge them and know they will be
          treated with respect, empathy and kindness.
        </p>
      </SideBySide>
    </>
  );
};
