import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import { TopNavigation } from "./components/TopNavigation";
import { BottomNavigation } from "./components/BottomNavigation";
import styled from "@emotion/styled";
import { padding } from "polished";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { useTheme } from "@emotion/react";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { urlToPageTitle } from "../../helpers/urlToPageTitle";
import { urls } from "../../helpers/urls";
import { Helmet } from "react-helmet";

const Page = styled.div({
  height: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
});

const PageOuter = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.colors.yellow,
  flexGrow: 1,
}));

const PageInner = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.white,
  maxWidth: 1100,
  width: "90%",
  [theme.breakpoints.phone]: {
    width: "100%",
  },
}));

const ComponentWrapper = styled.div(({ theme }) => ({
  ...theme.typography.Body,
  ...padding(
    theme.spacing[40],
    theme.spacing[60],
    theme.spacing[60],
    theme.spacing[60]
  ),
  color: theme.colors.black,
  ":first-child": {
    paddingTop: theme.spacing[60],
    [theme.breakpoints.phone]: {
      paddingTop: theme.spacing[40],
    },
  },
  [theme.breakpoints.phone]: {
    ...theme.typography.Caption,
    ...padding(theme.spacing[40], theme.spacing[32]),
    width: "100%",
  },
}));

const baseTitle = "Visting Neighbors";

export const RouteLayout: React.FC<
  Omit<RouteProps, "render" | "component"> & {
    component: React.FC;
  }
> = ({ component: Component, ...rest }) => {
  const theme = useTheme();
  const isTabletPortrait = useBreakpoint(theme.breakpoints.tabletPortrait);
  const { path } = rest;
  const pageTitle = React.useMemo(
    () =>
      !!path && typeof path === "string" && path !== urls.home && path !== ""
        ? `${baseTitle} | ${urlToPageTitle[path]}`
        : baseTitle,
    [path]
  );
  return (
    <Route
      {...rest}
      render={() => (
        <Page>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <TopNavigation />
          <PageOuter>
            <PageInner role="main">
              {isTabletPortrait ? null : <Breadcrumbs />}
              <ComponentWrapper>
                <Component />
              </ComponentWrapper>
            </PageInner>
          </PageOuter>
          <BottomNavigation />
        </Page>
      )}
    />
  );
};
