export const urls = {
  home: "/home",
  contact: "/contact",
  donate: "/donate",
  image: "/image",
  about: {
    main: "/about",
    history: "/about/history",
    whoWeServe: "/about/who-we-serve",
    staff: "/about/staff",
    impact: "/about/impact",
    notesSeniors: "/about/notes",
  },
  programs: {
    main: "/programs",
    friendlyVisiting: "/programs/friendly-visiting",
    shopAndEscort: "/programs/shop-and-escort",
    specialOccasions: "/programs/special-occasions",
    healthAndWellness: "/programs/health-and-wellness",
    therapeuticWalking: "/programs/therapeutic-walking",
    telephoneReassurance: "/programs/telephone-reassurance",
    faqs: "/programs/faqs",
    resources: "/programs/resources",
  },
  volunteer: {
    main: "/volunteer",
    getInvolved: "/volunteer/get-involved",
    applications: "/volunteer/applications",
    caseNotes: "/volunteer/case-notes",
    faqs: "/volunteer/faqs",
    quotes: "/volunteer/quotes",
  },
  facebook: "https://www.facebook.com/pg/Visiting-Neighbors-Inc-107328312426",
  linkedIn: "https://www.linkedin.com/company/visitingneighbors",
  donorbox: "https://donorbox.org/about-us",
};
