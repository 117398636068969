import * as React from "react";
import styled, { CSSObject } from "@emotion/styled";
import { Image } from "./Image";
import { padding } from "polished";

type SideBySideProps = {
  imgSrc: string;
  imgAlt: string;
  imgCaption?: React.ReactNode;
  reverse?: boolean;
  align?: "top" | "center";
  hasExtraSpace?: boolean;
  imageMaxWidth?: CSSObject["maxWidth"];
  isTransparent?: boolean;
};

const Wrapper = styled.div<
  Pick<SideBySideProps, "reverse" | "align" | "hasExtraSpace">
>(({ theme, reverse, align = "center", hasExtraSpace }) => ({
  ...(hasExtraSpace ? { ...padding(theme.spacing[32], 0) } : {}),
  display: "flex",
  flexDirection: reverse ? "row-reverse" : "row",
  alignItems: {
    top: "flex-start",
    center: "center",
  }[align],
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column-reverse",
    alignItems: "center",
  },
}));

const Content = styled.div({
  flexGrow: 1,
  flexBasis: "50%",
});

const Photo = styled.div<Pick<SideBySideProps, "reverse" | "imageMaxWidth">>(
  ({ theme, reverse, imageMaxWidth: maxWidth = 600 }) => ({
    ...padding(
      0,
      reverse ? theme.spacing[56] : 0,
      0,
      reverse ? 0 : theme.spacing[56]
    ),
    flexBasis: "50%",
    flexGrow: 1,
    flexShrink: 0,
    maxWidth,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: theme.spacing[16],
    [theme.breakpoints.tabletPortrait]: {
      ...padding(0, 0, theme.spacing[20], 0),
    },
    [theme.breakpoints.phone]: {
      maxWidth: "none",
      width: "100%",
    },
  })
);

const Caption = styled.span(({ theme }) => ({
  fontStyle: "italic",
  ...theme.typography.Caption,
}));

export const SideBySide: React.FC<SideBySideProps> = ({
  children,
  imgSrc,
  imgAlt,
  reverse,
  align,
  hasExtraSpace,
  imageMaxWidth,
  isTransparent,
  imgCaption,
}) => (
  <Wrapper reverse={reverse} align={align} hasExtraSpace={hasExtraSpace}>
    <Content>{children}</Content>
    <Photo reverse={reverse} imageMaxWidth={imageMaxWidth}>
      <Image src={imgSrc} alt={imgAlt} isTransparent={isTransparent} />
      <Caption>{imgCaption}</Caption>
    </Photo>
  </Wrapper>
);
