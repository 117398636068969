import * as React from "react";
import { Title } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";
import telephone from "../../assets/photos/telephoneReassurance.jpg";
import { Button } from "../../components/Button";
import { urls } from "../../helpers/urls";

export const TelephoneReassurance: React.FC = () => (
  <>
    <Title>Telephone reassurance</Title>
    <SideBySide
      imgSrc={telephone}
      imgAlt="Senior on the phone"
      align="top"
      hasExtraSpace
    >
      We have been making four types of calls to seniors by staff, board members
      and trained volunteers, depending on their level of experience and what
      the seniors’ needs are:
      <ol>
        <li>
          <b>Check-in calls:</b> seniors just want to receive a call to say that
          they are alright
        </li>
        <li>
          <b>Friendly visiting calls:</b> ranging from chatting to lengthy
          conversations
        </li>
        <li>
          <b>Emotional support calls:</b> reassurance & information sharing
          calls
        </li>
        <li>
          <b>
            Calls to new seniors who are/were sick or engaging in risky
            behaviors:
          </b>{" "}
          including not following health guidelines or proper protocols such as
          not wearing a mask, and are seeking caring, “tough love” responses and
          parameters from us to know they matter and resulting in safer choices.
        </li>
      </ol>
      <p>
        <Button href={urls.contact}>Contact us</Button>
      </p>
    </SideBySide>
  </>
);
