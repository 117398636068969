import * as React from "react";
import { Title } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";
import { Button } from "../../components/Button";
import { urls } from "../../helpers/urls";
import senior6 from "../../assets/photos/senior6.jpg";

export const FriendlyVisiting: React.FC = () => {
  return (
    <>
      <Title>Friendly Visiting and Shop & Escort</Title>

      <SideBySide
        imgSrc={senior6}
        imgAlt="Two friends sitting together"
        align="top"
        hasExtraSpace
      >
        Volunteers are matched with seniors based on mutual interests, hobbies,
        needs, etc. to spend a couple of hours a week providing companionship
        and to make a friend, or just to sit and chat over a cup of tea.
        Volunteers take seniors to and from important medical appointments, pick
        up seniors after medical procedures, take them to and from physical
        therapy, accompany them on walks, help with errands (such as shopping to
        get access to food and vital supplies, escorts to/from banks, helping
        read mail, hair salons, social programs, physical therapy, cemetery
        visits etc.)
        <p>
          Volunteers have also accompanied seniors with pets get to vets. Our
          staff also encouraged seniors to vote, including helping seniors mail
          absentee ballots when they couldn’t get out. Volunteers escort seniors
          who want to vote in person and we were promoting seniors using
          absentee ballots.
        </p>
        <p>
          During the pandemic, We have focused on doing shopping and vital
          errands for seniors, though we are now doing it in different ways –
          rather than our usual going with seniors to encourage them to go out,
          we go for our clients and have been constantly updating volunteer
          protocols to keep everyone as safe as possible.
        </p>
        <p>
          <Button href={urls.contact}>Contact us</Button>
        </p>
      </SideBySide>
    </>
  );
};
