import { urls } from "./urls";

export const urlToPageTitle = {
  [urls.home]: "Home",
  [urls.contact]: "Contact",
  [urls.donate]: "Donate",
  [urls.about.main]: "About",
  [urls.about.history]: "History",
  [urls.about.whoWeServe]: "Who we serve",
  [urls.about.staff]: "Staff",
  [urls.about.impact]: "Impact",
  [urls.about.notesSeniors]: "Senior letters",
  [urls.programs.main]: "Programs",
  [urls.programs.friendlyVisiting]: "Friendly visiting",
  [urls.programs.shopAndEscort]: "Shop & escort",
  [urls.programs.healthAndWellness]: "Health & wellness",
  [urls.programs.therapeuticWalking]: "Therapeutic walking",
  [urls.programs.specialOccasions]: "Special occasions",
  [urls.programs.telephoneReassurance]: "Telephone reassurance",
  [urls.programs.faqs]: "Program FAQs",
  [urls.programs.resources]: "Resources",
  [urls.volunteer.main]: "Volunteering",
  [urls.volunteer.getInvolved]: "Get involved",
  [urls.volunteer.applications]: "Application",
  [urls.volunteer.caseNotes]: "Client case notes",
  [urls.volunteer.faqs]: "Volunteer FAQs",
  [urls.volunteer.quotes]: "Volunteer quotes",
};
