import * as React from "react";
import { DocumentDisclaimer } from "./DocumentDisclaimer";
import { Drive } from "./Drive";
import { Subtitle, Title } from "../../components/Typography";

export const Resources: React.FC = () => {
  return (
    <>
      <Title>Resources</Title>
      <DocumentDisclaimer />
      <Subtitle withMargin>Newsletters</Subtitle>
      <Drive type="newsletters" format="grid" />
      <Subtitle withMargin>Brochures</Subtitle>
      <Drive type="brochures" format="grid" />
    </>
  );
};
