type Values = {
  email: string;
  subject: string;
  message: string;
  name?: string;
  phone?: string;
  source?: string;
};

type ValuesProps =
  | "name"
  | "email"
  | "phone"
  | "subject"
  | "source"
  | "message";

export const useContactTemplate = (values: Values) => {
  const { REACT_APP_EMAILJS_TEMPLATEID_CONTACT } = process.env as any;

  return {
    templateParams: (Object.keys(values) as ValuesProps[]).reduce(
      (map, key) => ({
        ...map,
        [key]: values[key] ?? "",
      }),
      {}
    ),
    templateId: REACT_APP_EMAILJS_TEMPLATEID_CONTACT,
  };
};
