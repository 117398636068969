import * as React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { AboutUs } from "./features/About/AboutUs";
import { History } from "./features/About/History";
import { Staff } from "./features/About/Staff";
import { WhoWeServe } from "./features/About/WhoWeServe";
import { Contact } from "./features/Contact";
import { Donate } from "./features/Donate";
import { Home } from "./features/Home";
import { CommunityImpact } from "./features/Impact/CommunityImpact";
import { NotesFromSeniors } from "./features/Impact/NotesFromSeniors";
import { Quotes } from "./features/Impact/Quotes";
import { Resources } from "./features/Resources/Resources";
import { FriendlyVisiting } from "./features/Programs/FriendlyVisiting";
import { SpecialOccasions } from "./features/Programs/SpecialOccasions";
import { HealthAndWellness } from "./features/Programs/HealthAndWellness";
import { ProgramFAQs } from "./features/Programs/ProgramFAQs";
import { Programs } from "./features/Programs/Programs";
import { TelephoneReassurance } from "./features/Programs/TelephoneReassurance";
import { TherapeuticWalking } from "./features/Programs/TherapeuticWalking";
import { RouteLayout } from "./features/RouteLayout";
import { ViewImage } from "./features/ViewImage";
import { Applications } from "./features/Volunteer/Applications";
import { CaseNotes } from "./features/Volunteer/CaseNotes";
import { GetInvolved } from "./features/Volunteer/GetInvolved";
import { Volunteer } from "./features/Volunteer/Volunteer";
import { VolunteerFAQs } from "./features/Volunteer/VolunteerFAQs";
import { urls } from "./helpers/urls";

export const Router: React.FC = () => (
  <div>
    <Switch>
      <RouteLayout exact path={urls.about.history} component={History} />
      <RouteLayout exact path={urls.about.whoWeServe} component={WhoWeServe} />
      <RouteLayout exact path={urls.about.staff} component={Staff} />
      <RouteLayout exact path={urls.about.impact} component={CommunityImpact} />
      <RouteLayout
        exact
        path={urls.about.notesSeniors}
        component={NotesFromSeniors}
      />
      <RouteLayout path={urls.about.main} component={AboutUs} />

      <RouteLayout exact path={urls.programs.faqs} component={ProgramFAQs} />
      <RouteLayout
        path={urls.programs.friendlyVisiting}
        component={FriendlyVisiting}
      />
      <RouteLayout
        path={urls.programs.shopAndEscort}
        component={FriendlyVisiting}
      />
      <RouteLayout
        path={urls.programs.specialOccasions}
        component={SpecialOccasions}
      />
      <RouteLayout
        path={urls.programs.healthAndWellness}
        component={HealthAndWellness}
      />
      <RouteLayout
        path={urls.programs.telephoneReassurance}
        component={TelephoneReassurance}
      />
      <RouteLayout
        path={urls.programs.therapeuticWalking}
        component={TherapeuticWalking}
      />
      <RouteLayout exact path={urls.programs.resources} component={Resources} />
      <RouteLayout path={urls.programs.main} component={Programs} />

      <RouteLayout exact path={urls.volunteer.faqs} component={VolunteerFAQs} />
      <RouteLayout
        exact
        path={urls.volunteer.caseNotes}
        component={CaseNotes}
      />
      <RouteLayout
        exact
        path={urls.volunteer.applications}
        component={Applications}
      />
      <RouteLayout
        exact
        path={urls.volunteer.getInvolved}
        component={GetInvolved}
      />
      <RouteLayout exact path={urls.volunteer.quotes} component={Quotes} />
      <RouteLayout path={urls.volunteer.main} component={Volunteer} />

      <RouteLayout exact path={urls.contact} component={Contact} />
      <RouteLayout exact path={urls.donate} component={Donate} />
      <Route exact path={urls.image} component={ViewImage} />
      <RouteLayout exact path={urls.home} component={Home} />
      <RouteLayout
        exact
        path="index.html"
        component={() => <Redirect to={urls.home} />}
      />
      <RouteLayout component={() => <Redirect to={urls.home} />} />
    </Switch>
  </div>
);
