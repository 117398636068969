import * as React from "react";
import styled from "@emotion/styled";

const StyledDrive = styled.iframe(({ theme }) => ({
  width: "100%",
  height: 300,
  border: `1px solid ${theme.colors.gray100}`,
  backgroundColor: theme.colors.gray,
  [theme.breakpoints.phone]: {
    height: 600,
  },
}));

const ids = {
  forms: "1uez00MCpImz963g2wtrl-iA9G9cdiVvF",
  newsletters: "1Scqh2Wm170AiBjgBSrYUPnrm6lF3XE97",
  brochures: "1FnCAWj_rjZTx9tufkLz6P7-gUckeIfcG",
};

type DriveProps = {
  format: "list" | "grid";
  type: "forms" | "newsletters" | "brochures";
};

export const Drive: React.FC<DriveProps> = ({ format, type }) => (
  <StyledDrive
    title={type}
    src={`https://drive.google.com/embeddedfolderview?id=${ids[type]}#${format}`}
  />
);
