import * as React from "react";
import { Link } from "../../../components/Link";
import styled from "@emotion/styled";

export const Header = styled.div(({ theme }) => ({
  ...theme.typography.Header,
  [theme.breakpoints.tabletLandscape]: {
    ...theme.typography.Body,
    fontWeight: "bold",
  },
}));

type NavigationItemLinkProps = {
  href: string;
};

export const NavigationItemLink: React.FC<NavigationItemLinkProps> = ({
  href,
  children,
}) => {
  const onClick = React.useCallback(() => {
    window.location = href as any;
  }, [href]);
  return (
    <Link variation="light" onClick={onClick}>
      <Header>{children}</Header>
    </Link>
  );
};
