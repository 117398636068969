import * as React from "react";
import { Title } from "../../components/Typography";
import styled from "@emotion/styled";

const Question = styled.div(({ theme }) => ({
  color: theme.colors.aqua,
  fontWeight: theme.fontWeights.bold,
}));

export const VolunteerFAQs: React.FC = () => {
  return (
    <>
      <Title>Volunteer FAQs</Title>
      <p>
        <Question>If I become a volunteer, what am I expected to do?</Question>
        Volunteer visitors spend time with their seniors, enjoying shared
        activities that they decide together. Some seniors enjoy going outside
        for a walk, while others prefer staying home sharing conversation,
        watching TV or a movie, or playing cards. When we match a volunteer with
        a senior, we consider each person's preferences and interests. When we
        interview a volunteer, we discuss their interests, any special needs
        they may have, and what they are comfortable or not comfortable with.
        Our volunteers are here for companionship and emotional support, not to
        do chores in the senior's home. Volunteering should be a rewarding
        experience, not a tedious one.
      </p>
      <p>
        <Question>How long a commitment do I have to make?</Question>
        We do not ask for a specific commitment. Our volunteer coordinator will
        discuss your availability and involvement when he interviews you.
      </p>
      <p>
        <Question>Is there a minimum age for volunteers?</Question>
        Our volunteers range in age from 15 to 79. They come from all
        backgrounds and walks of life.
      </p>
      <p>
        <Question>I work a 40-hour week. Can I still volunteer?</Question>
        Many of our volunteers work at full-time jobs, and enjoy visiting a
        senior on their lunch hour, after work or on a weekend. All it takes is
        an hour or two a week to make a big difference in someone's life, as
        well as your own. The most important thing is the quality, not the
        quantity, of the time you spend together. We also have one-time and
        special project volunteer opportunities.
      </p>
      <p>
        <Question>What should I do in an emergency?</Question>
        When you become a volunteer, you will receive training about how to
        handle any difficulties that may arise. If you encounter an emergency,
        call 911. If the emergency occurs during normal business hours, call the
        VN office after you call 911.{" "}
      </p>
      <p>
        <Question>
          If I can't visit someone on a regular basis, can I still volunteer? Do
          you have any one-time volunteer projects?
        </Question>
        There are many ways you can volunteer to impact our seniors. You can
        become an "on-call" volunteer visitor who fills in if a senior's regular
        volunteer visitor is on vacation or not available. You can be "on call"
        to assist with an errand, or to escort a senior. If you have a special
        skill, such as graphics or writing, we can always use your help. We also
        need help with special projects, such as volunteer "elves" to deliver
        holiday gifts, or to help in the office, or with fundraising activities.
      </p>
    </>
  );
};
