import * as React from "react";

export function sanitizeMq(mq: string) {
  return mq.replace("@media", "").trim();
}

function getMediaQueryList(mediaQuery: string) {
  return typeof window !== "undefined" ? window.matchMedia(mediaQuery) : null;
}

export function useBreakpoint(mediaQuery: string): boolean {
  const sanitizedMediaQuery = React.useMemo(() => {
    return sanitizeMq(mediaQuery);
  }, [mediaQuery]);

  const mediaQueryListRef = React.useRef(
    getMediaQueryList(sanitizedMediaQuery)
  );

  const [isActive, setIsActive] = React.useState(() =>
    Boolean(mediaQueryListRef.current?.matches)
  );

  // Update the mediaQueryList if the mediaQuery changes
  React.useEffect(() => {
    const mediaQueryList = getMediaQueryList(sanitizedMediaQuery);
    mediaQueryListRef.current = mediaQueryList;
    setIsActive(Boolean(mediaQueryList?.matches));

    const handleChange = (e: MediaQueryListEvent) => {
      setIsActive(e.matches);
    };

    mediaQueryList?.addListener(handleChange);

    return () => {
      mediaQueryList?.removeListener(handleChange);
    };
  }, [sanitizedMediaQuery, setIsActive, mediaQueryListRef]);

  return isActive;
}
