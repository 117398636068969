import * as React from "react";
import { SideBySide } from "../../components/SideBySide";
import { Title } from "../../components/Typography";
import king from "../../assets/photos/king.jpg";
import volunteer from "../../assets/photos/volunteerQuotes.jpg";
import laughing from "../../assets/photos/laughing.jpg";
import { volunteerQuotes } from "./content/volunteerQuotes";

export const Quotes: React.FC = () => {
  const first = volunteerQuotes.slice(0, 4);
  const middle = volunteerQuotes.slice(4, 8);
  const last = volunteerQuotes.slice(8, volunteerQuotes.length);
  return (
    <>
      <Title>Quotes from volunteers</Title>
      <SideBySide
        imgAlt="We treat our seniors like kings!"
        imgSrc={king}
        hasExtraSpace
      >
        {first.map(({ quote, person }) => (
          <p key={person}>
            <i>"{quote}"</i>
            <br />
            <b>- {person}</b>
          </p>
        ))}
      </SideBySide>
      <SideBySide imgAlt="Volunteering" imgSrc={volunteer} reverse>
        {middle.map(({ quote, person }) => (
          <p key={person}>
            <i>"{quote}"</i>
            <br />
            <b>- {person}</b>
          </p>
        ))}
      </SideBySide>
      <SideBySide imgAlt="Friends laughing" imgSrc={laughing} hasExtraSpace>
        {last.map(({ quote, person }) => (
          <p key={person}>
            <i>"{quote}"</i>
            <br />
            <b>- {person}</b>
          </p>
        ))}
      </SideBySide>
    </>
  );
};
