import * as React from "react";
import { Link } from "../../components/Link";
import { urls } from "../../helpers/urls";
import { Title } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";
import Vaccine from "../../assets/photos/vaccine.jpg";
import Birthday from "../../assets/photos/birthday.jpg";

export const CommunityImpact: React.FC = () => {
  return (
    <>
      <Title>Community impact</Title>
      <SideBySide
        imgSrc={Vaccine}
        imgAlt="Senior getting vaccine with volunteer"
        hasExtraSpace
      >
        During the pandemic, Visiting Neighbors has been active and open with
        staff in the office and has continued operating regularly, with the
        provision of certain restrictions put in place by the CDC. Protocols
        have been kept in place (and regularly updated) to keep everyone
        (volunteers, seniors and staff) as safe as possible.
        <p>
          As of June 2020, as medical appointments and procedures were on the
          schedule again, volunteers resumed escorts for seniors for their
          medical appointments and procedures. When needed, seniors have also
          been escorted to/from the polls for voting, banks and other vital
          errands. For those unable to go out, volunteers assisted seniors with
          mail in ballots, drops off to post offices, getting seniors’ pets to
          vets, picking up from pharmacies and delivery of those medicines and
          other medical/health related supplies and getting/bringing seniors
          mail to them when they couldn’t leave their apartments.
        </p>
      </SideBySide>
      <p>
        <b>Contactless shopping & errands</b> for Visiting Neighbors’ seniors
        have continued since the start of the pandemic. Access to food and
        essential supplies continues to be a main focus, as does, having seniors
        maintain connections to the outside, others and correct health
        information. We continue working with other charities, one of which
        delivers “gourmet meals” to our seniors who are in their catchment area.
        Those seniors really looked forward during this crisis to the delicious
        meals they received – especially the Saturday meals and we continue to
        work with local restaurants that have been able and willing to donate
        meals to our seniors that our volunteers then deliver.
      </p>
      <p>
        In an abundance of caution for both our seniors and volunteers,{" "}
        <Link href={urls.programs.friendlyVisiting} variation="highlighted">
          Friendly Visiting
        </Link>{" "}
        has happened with some necessary restrictions (volunteers setting up
        chairs in hall ways, visiting with the needed distance in lobbies or
        visiting at home with PPE and with the proper 6ft distance).
      </p>
      <p>
        <Link href={urls.programs.telephoneReassurance} variation="highlighted">
          Telephone Reassurance
        </Link>{" "}
        program has been operating in full force —- over 13,000 hours to date
        from the start of FY2021. Volunteers and staff contact seniors who
        were/are feeling isolated, very lonely and afraid or want a check-in
        call to get updated information or to make sure they are ok.
      </p>
      <SideBySide
        imgSrc={Birthday}
        imgAlt="Senior birthday"
        reverse
        align="top"
        hasExtraSpace
      >
        <p>
          Through{" "}
          <Link href={urls.programs.healthAndWellness} variation="highlighted">
            Health & Wellness
          </Link>{" "}
          and Info, Visiting Neighbors sent out numerous mailings from July 1st
          2020 to present to seniors that included safety information, health
          and wellness tips including staying calm, coping with depression and
          communications with medical professionals, in home (easy, some chair
          exercise and stretches) exercises and mental stimulation through
          games, quizzes, puzzles and riddles. Staff has done a lot of cheering
          up – not only seniors, but to volunteers (ages 17- 78), who also
          expressed sadness, anxiety, depression, anger, fear and frustration.
          We reminded everyone around us that we were open and are here to
          assist them whenever they needed us.
        </p>
        <p>
          Even more essential now, we continue to celebrate a senior’s birthday
          with sending personalized cards and a special tribute to a senior
          reaching 100 years old by joining our <b>Centenarian Club</b>, which
          currently has a dozen members and could have 16 by the summer. We
          honor all of our seniors and support them as they struggle with the
          normal and not so normal challenges of aging. 8. We also dealt with a
          lot of mourning and grief (some caused by COVID-19) and all the
          painful emotions that go with it and have provided individual
          emotional support to seniors and volunteers who have suffered loss of
          spouse/partner, child, sibling or other relative, friend, neighbor or
          pet.
        </p>
      </SideBySide>
    </>
  );
};
