import * as React from "react";
import styled from "@emotion/styled";
import { phone, email } from "../../../helpers/constants";
import { StayConnected } from "./StayConnected";
import { border } from "polished";
import { Link } from "../../../components/Link";

const Wrapper = styled.div(({ theme }) => ({
  ...border("bottom", 1, "solid", theme.colors.black),
  marginBottom: 20,
  paddingBottom: 20,
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.phone]: {
    border: 0,
    marginTop: 0,
    paddingTop: 0,
    display: "flex",
    flexDirection: "column-reverse",
    gap: 0,
  },
}));

const Address = styled.div(({ theme }) => ({
  ...theme.typography.CaptionM,
  textAlign: "right",
  flexGrow: 1,
  flexBasis: "50%",
  [theme.breakpoints.phone]: {
    textAlign: "left",
  },
}));

export const Footer: React.FC = () => (
  <Wrapper>
    <StayConnected />
    <Address>
      3 Washington Square Village, Suite&nbsp;1F
      <br />
      New York, NY 10012
      <br />
      {phone}
      <p>
        <Link href={`mailto:${email}`} variation="highlighted">
          {email}
        </Link>
      </p>
    </Address>
  </Wrapper>
);
