import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "./Link";
import { Zoom } from "./Icons";
import { padding } from "polished";
import qs from "qs";
import { urls } from "../helpers/urls";

const Wrapper = styled.div(({ theme }) => ({
  paddingTop: theme.spacing[4],
}));

const Icon = styled.div(({ theme }) => ({
  ...padding(0, theme.spacing[4], theme.spacing[4], 0),
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
}));

type ViewLargerProps = {
  src: string;
};

export const ViewLarger: React.FC<ViewLargerProps> = ({ src }) => (
  <Link
    href={`${urls.image}?${qs.stringify({
      src,
    })}`}
    openInNewWindow
    variation="highlighted"
  >
    <Wrapper>
      <Icon>
        <Zoom size="small" />
      </Icon>
      View larger
    </Wrapper>
  </Link>
);
