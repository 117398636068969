import * as React from "react";
import { Subtitle, Title } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";
import Health9 from "../../assets/photos/health9.jpg";
import healthWellness from "../../assets/photos/healthWellness.jpg";
import { Button } from "../../components/Button";
import { urls } from "../../helpers/urls";

export const HealthAndWellness: React.FC = () => {
  return (
    <>
      <Title>Health & wellness</Title>
      <SideBySide
        imgSrc={healthWellness}
        imgAlt="Hypertension presentation"
        hasExtraSpace
      >
        <Subtitle withMargin>Health advocacy</Subtitle>
        Our Health Advocate and staff help our existing clients better
        communicate with their doctors, as well as formulate key questions to
        ask medical professionals to ensure they understand instructions when
        leaving their offices.
        <p>
          Our Health Advocate and trained cadre of volunteers also encourages
          seniors to go to see their doctors in the first place, as well as
          advocate for them when they are going into the hospital. It always
          helps a patient to let medical personnel know someone is watching.
          When a senior comes home from a hospital, we are there to make sure
          they have what they need. We will pick up medications and go shopping,
          as well as provide emotional reassurance and a chance to vent about
          their experience.
        </p>
      </SideBySide>
      <SideBySide
        imgSrc={Health9}
        imgAlt="Health and wellness event"
        align="top"
      >
        <Subtitle withMargin>Health & wellness programs</Subtitle>
        We address seniors’ wellness concerns including needs for physical
        activity, mental stimulation, coping with a variety of concerns and
        strengthening one’s self-esteem. We help seniors stay informed with
        accurate and updated information and in-person wellness discussions on a
        variety of issues and topics including:
        <ul>
          <li>stretching/chair exercises/staying flexible</li>
          <li>stress management</li>
          <li>nutrition/incorporating super foods </li>
          <li>the importance of staying connected with others</li>
          <li>
            staying strong as the seniors face physical changes/venting
            frustrations in healthy ways
          </li>
          <li>beating the blues</li>
          <li>fall prevention</li>
          <li>advanced directives</li>
          <li>communicating more effectively with medical professionals</li>
          <li>mental stimulation through games and conversation</li>
          <li>emergency preparedness</li>
          <li>heart health</li>
          <li>pedestrian safety</li>
          <li>safety in the home</li>
          <li>addressing agism</li>
          <li>venting about COVID-19 (coming soon)</li>
        </ul>
        <p>
          <Button href={urls.contact}>Contact us</Button>
        </p>
      </SideBySide>
    </>
  );
};
