import * as React from "react";
import styled from "@emotion/styled";
import { Button } from "./Button";
import { padding, border, margin } from "polished";

type ImageProps = { src: string };

const Image = styled.div<ImageProps>(({ theme, src }) => ({
  ...border(1, "solid", theme.colors.gray100),
  borderBottom: 0,
  borderTopLeftRadius: theme.spacing[8],
  borderTopRightRadius: theme.spacing[8],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url("${src}")`,
  backgroundSize: "cover",
  height: 184,
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  paddingTop: theme.spacing[16],
}));

const Content = styled.div(({ theme }) => ({
  ...padding(theme.spacing[16], theme.spacing[24]),
  borderTop: 0,
  borderBottomLeftRadius: theme.spacing[8],
  borderBottomRightRadius: theme.spacing[8],
  backgroundColor: theme.colors.gray,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
  textAlign: "center",
  width: "100%",
}));

const Wrapper = styled.div(({ theme }) => ({
  ...margin(0, theme.spacing[8]),
  borderRadius: theme.borders.radius.default,
  alignSelf: "stretch",
  display: "flex",
  flexDirection: "column",
  flexBasis: "33%",
  ":first-of-type": {
    marginLeft: 0,
  },
  ":last-of-type": {
    marginRight: 0,
  },
  [theme.breakpoints.tabletPortrait]: {
    ...margin(0, 0, theme.spacing[12], 0),
    flexBasis: "100%",
  },
}));

const Title = styled.div(({ theme }) => ({
  ...theme.typography.HeaderB,
  width: "100%",
  paddingBottom: theme.spacing[8],
  textAlign: "center",
}));

const Description = styled.div({
  maxWidth: "100%",
  textAlign: "left",
});

type ActionBoxProps = {
  cta: string;
  url: string;
  imgSrc: string;
  imgAlt: string;
  title: string;
};

export const ActionBox: React.FC<ActionBoxProps> = ({
  cta,
  url,
  imgSrc,
  imgAlt,
  children,
  title,
}) => (
  <Wrapper>
    <Image role="img" aria-label={imgAlt} src={imgSrc}></Image>
    <Content>
      <Title>{title}</Title>
      <Description>{children}</Description>
      <ButtonWrapper>
        <Button href={url}>{cta}</Button>
      </ButtonWrapper>
    </Content>
  </Wrapper>
);
