import Howie from "../../../assets/staff/howie.jpg";
import Steve from "../../../assets/staff/steve.jpg";
import Mary from "../../../assets/staff/mary.jpg";
import Cynthia from "../../../assets/staff/cynthia.jpg";

export const staff = [
  {
    photo: Cynthia,
    name: "Cynthia Maurer, Ph.D.",
    role: "Executive Director",
    bio: "Cynthia has been Executive Director of Visiting Neighbors since 1996. She had previously served as Community Organizer, helping to maintain and strengthen Visiting Neighbors’ core programs and services, as well as develop new ones. She completed her doctoral dissertation in Psychology at The New School specializing in Social & Personality Psychology and Gerontology. Cynthia has been a caregiver most of her life.",
    strengths:
      "Cynthia’s three greatest strengths at VN are her ability to always stay positive and hopeful no matter the situation, her ability to empathize and be emotionally supportive of others and her undeniable talent for cheering people up.",
  },
  {
    photo: Howie,
    name: "Howie Square",
    role: "Volunteer Coordinator",
    bio: "Howie joined Visiting Neighbors in 1999 after serving as an Americorps member. Working with a vulnerable population comes naturally to him. He previously worked as a substance abuse counselor, a camp counselor, and an after-school program officer. His people skills help him build successful matches between prospective volunteers and seniors who need Visiting Neighbors assistance.",
    strengths:
      "Howie’s three greatest strengths at VN are his friendliness, compassion and his ability to go get stuff for seniors donated.",
  },
  {
    photo: Steve,
    name: "Steve Gould",
    role: "Health Advocate, Intake Worker",
    bio: "Steve joined the VN staff in 2006 after 40 years in the Entertainment Industry. He returned to where his heart truly was – helping seniors. In 1982, he served as the second President of Village Visiting Neighbors. When his term was up, he remained a dedicated VN volunteer for decades before becoming a staff member.",
    strengths:
      "Steve’s three greatest strengths at VN are his ability to research an issue for a client, his anecdotes to best describe a situation to help a volunteer gain clarity and his sarcastic sense of humor that many clients relate to.",
  },
  {
    photo: Mary,
    name: "Mary Brosnan",
    role: "Assistant Program Coordinator",
    bio: "Mary joined Visiting Neighbors over a decade ago, filling in whenever needed. She helps kept VN projects on track. She is committed to helping seniors remain informed, independent and safe.",
    strengths:
      "Mary's three greatest strengths at VN are her administrative and organization skills, and her ability to remain calm and continue to work efficiently under pressure.",
  },
];
