import * as React from "react";
import styled from "@emotion/styled";

export type IconProps = {
  size?: "small" | "medium" | "large";
};

const iconSizeToValue = {
  small: 16,
  medium: 20,
  large: 32,
};

const Icon = styled.div<IconProps>(({ size = "large" }) => ({
  width: iconSizeToValue[size],
  height: iconSizeToValue[size],
  svg: {
    display: "block",
    width: "100%",
    height: "100%",
  },
}));

export const Facebook: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg viewBox="0 0 18 18" fill="currentColor" aria-label="Facebook logo">
      <path d="M2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0V0ZM15 2H12.5C11.5717 2 10.6815 2.36875 10.0251 3.02513C9.36875 3.6815 9 4.57174 9 5.5V8H7V11H9V18H12V11H15V8H12V6C12 5.73478 12.1054 5.48043 12.2929 5.29289C12.4804 5.10536 12.7348 5 13 5H15V2Z" />
    </svg>
  </Icon>
);

export const LinkedIn: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg viewBox="0 0 659 662" aria-label="LinkedIn logo">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.4288 0C22.5835 0 0.808594 21.2453 0.808594 47.4221V614.595C0.808594 640.772 22.5835 662 49.4288 662H610.021C636.875 662 658.641 640.772 658.641 614.587V47.4221C658.641 21.2453 636.875 0 610.021 0H49.4288ZM200.221 255.23V554.158H100.862V255.23H200.221ZM206.768 162.782C206.768 191.468 185.201 214.424 150.559 214.424H150.551H149.908C116.56 214.424 95.002 191.468 95.002 162.782C95.002 133.445 117.211 111.132 151.219 111.132C185.201 111.132 206.117 133.445 206.768 162.782ZM354.565 554.158H255.215C255.223 552.399 256.513 283.191 255.223 255.239H354.574V297.557C367.779 277.191 391.416 248.224 444.122 248.224C509.49 248.224 558.501 290.949 558.501 382.763V554.158H459.151V394.258C459.151 354.069 444.764 326.659 408.82 326.659C381.367 326.659 365.018 345.143 357.838 363.003C355.216 369.375 354.565 378.318 354.565 387.243V554.158ZM255.215 554.167V554.158C255.214 554.164 255.215 554.167 255.215 554.167Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);

export const MenuIcon: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg viewBox="0 0 29 18" fill="currentColor">
      <rect width="29" height="4" rx="2" />
      <rect y="6.99268" width="29" height="4" rx="2" />
      <rect y="13.9853" width="29" height="4" rx="2" />
    </svg>
  </Icon>
);

export const Caret: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg viewBox="0 0 26 45" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.951896 44.0584C-0.317313 42.8032 -0.317313 40.7681 0.951896 39.513L18.1538 22.5L0.951897 5.48711C-0.317311 4.23187 -0.317311 2.1967 0.951897 0.941444C2.22111 -0.313816 4.27889 -0.313816 5.5481 0.941444L25.0481 20.2272C26.3173 21.4824 26.3173 23.5176 25.0481 24.7728L5.5481 44.0584C4.27889 45.3139 2.2211 45.3139 0.951896 44.0584Z"
      />
    </svg>
  </Icon>
);

export const Close: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg viewBox="0 0 12 12" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7489 0.251051C12.0837 0.585786 12.0837 1.1285 11.7489 1.46323L1.46323 11.7489C1.1285 12.0837 0.585786 12.0837 0.251051 11.7489C-0.0836838 11.4142 -0.0836838 10.8715 0.251051 10.5368L10.5368 0.251051C10.8715 -0.0836838 11.4142 -0.0836838 11.7489 0.251051Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.251051 0.251051C0.585786 -0.0836838 1.1285 -0.0836838 1.46323 0.251051L11.7489 10.5368C12.0837 10.8715 12.0837 11.4142 11.7489 11.7489C11.4142 12.0837 10.8715 12.0837 10.5368 11.7489L0.251051 1.46323C-0.0836838 1.1285 -0.0836838 0.585786 0.251051 0.251051Z"
      />
    </svg>
  </Icon>
);

export const Alert: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  </Icon>
);

export const Email: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
      <polyline points="22,6 12,13 2,6"></polyline>
    </svg>
  </Icon>
);

export const File: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
      <polyline points="14 2 14 8 20 8"></polyline>
      <line x1="16" y1="13" x2="8" y2="13"></line>
      <line x1="16" y1="17" x2="8" y2="17"></line>
      <polyline points="10 9 9 9 8 9"></polyline>
    </svg>
  </Icon>
);

export const Award: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="8" r="7"></circle>
      <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
    </svg>
  </Icon>
);

export const Zoom: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      <line x1="11" y1="8" x2="11" y2="14"></line>
      <line x1="8" y1="11" x2="14" y2="11"></line>
    </svg>
  </Icon>
);

export const Lock: React.FC<IconProps> = ({ size }) => (
  <Icon size={size}>
    <svg viewBox="0 0 24 24" aria-hidden="true">
      <path
        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
        fill="currentColor"
      ></path>
    </svg>
  </Icon>
);
