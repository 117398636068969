import * as React from "react";
import styled from "@emotion/styled";
import { border } from "polished";

const Wrapper = styled.img<{ isTransparent?: boolean }>(
  ({ theme, isTransparent }) => [
    {
      width: "100%",
      // fix for IE https://stackoverflow.com/a/33505912/605511
      minHeight: 1,
      height: "auto",
    },
    !isTransparent && {
      ...border(1, "solid", theme.colors.gray100),
      borderRadius: theme.borders.radius.default,
      backgroundColor: theme.colors.gray,
    },
  ]
);

type ImageProps = {
  src: string;
  alt: string;
  isTransparent?: boolean;
};

export const Image: React.FC<ImageProps> = ({ src, alt, isTransparent }) => (
  <Wrapper src={src} alt={alt} isTransparent={isTransparent} />
);
