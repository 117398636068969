import * as React from "react";
import styled from "@emotion/styled";
import { size, padding } from "polished";
import { defaultTheme } from "../../helpers/theming";

type ColorProps = {
  color: "red" | "yellow" | "aqua" | "gray";
};

const typeToColor = {
  red: defaultTheme.colors.red,
  aqua: defaultTheme.colors.aqua,
  yellow: defaultTheme.colors.yellow,
  gray: defaultTheme.colors.gray200,
};

const Wrapper = styled.div(({ theme }) => ({
  ...padding(theme.spacing[20], theme.spacing[16]),
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
}));

const Circle = styled.div<ColorProps>(({ theme, color }) => ({
  ...size(48),
  borderRadius: "50%",
  boxShadow: `0 0 0 2px ${theme.colors.white}, 0 0 0 4px ${typeToColor[color]}`,
  backgroundColor: typeToColor[color],
  flexShrink: 0,
}));

export const Subtitle = styled.div(({ theme }) => ({
  ...theme.typography.HeaderB,
  ...padding(theme.spacing[16], 0, theme.spacing[8], 0),
  [theme.breakpoints.tabletPortrait]: {
    ...theme.typography.Header,
  },
}));

const Content = styled.div(({ theme }) => ({
  color: theme.colors.gray300,
  width: 200,
  [theme.breakpoints.phone]: {
    width: 150,
  },
}));

export const Color: React.FC<ColorProps> = ({ color, children }) => (
  <Wrapper>
    <Circle color={color} />
    <Subtitle>{`${color[0].toUpperCase()}${color.slice(1)}`}</Subtitle>
    <Content>{children}</Content>
  </Wrapper>
);
