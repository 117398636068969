import React from "react";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Route } from "react-router-dom";
import { Router } from "./Router";
import { defaultTheme } from "./helpers/theming";
import { init } from "emailjs-com";
import { BodyScrollProvider } from "./hooks/useBodyScrollLock";
import { ScrollToTop } from "./components/ScrollToTop";

init(process.env.REACT_APP_EMAILJS_USERID ?? "");

const App: React.FC = () => {
  return (
    <BodyScrollProvider>
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider theme={defaultTheme}>
          <Route path="/" component={Router} />
        </ThemeProvider>
      </BrowserRouter>
    </BodyScrollProvider>
  );
};

export default App;
