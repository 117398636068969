import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "../components/Link";
import { padding, border, margin } from "polished";
import {
  phoneLink as officePhoneLink,
  phone as officePhone,
  email as officeEmail,
} from "../helpers/constants";
import { Subtitle, Title } from "../components/Typography";
import { Button } from "../components/Button";
import { InputField } from "../components/InputField";
import { useForm } from "../hooks/useForm";
import { isValidEmail } from "../helpers/isValidEmail";
import { SelectField } from "../components/SelectField";
import { useContactTemplate } from "../hooks/useContactTemplate";

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column-reverse",
  },
}));

const General = styled.div(({ theme }) => ({
  ...border("left", 1, "solid", theme.colors.gray),
  paddingLeft: theme.spacing[32],
  marginLeft: theme.spacing[56],
  flexBasis: "40%",
  [theme.breakpoints.tabletPortrait]: {
    ...padding(theme.spacing[16], 0, theme.spacing[16], 0),
    ...margin(theme.spacing[16], 0, theme.spacing[16], 0),
    ...border("bottom", 1, "solid", theme.colors.gray),
    ...border("top", 1, "solid", theme.colors.gray),
    borderLeft: "none",
  },
}));

const Form = styled.div({
  flexBasis: "60%",
});

const InlineFieldsWrapper = styled.div(({ theme }) => ({
  display: "grid",
  gap: theme.spacing[16],
  gridTemplateColumns: "1fr 1fr",
  paddingBottom: theme.spacing[16],
  [theme.breakpoints.tabletPortrait]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const FieldWrapper = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing[16],
}));

const Strong = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
}));

const SourceDescription = styled.div(({ theme }) => ({
  ...border(1, "solid", theme.colors.black),
  ...theme.typography.CaptionM,
  ...padding(theme.spacing[12], theme.spacing[8]),
  marginTop: theme.spacing[12],
  background: theme.colors.gray,
  borderRadius: theme.borders.radius.small,
}));

enum Fields {
  firstName = "First name",
  lastName = "Last name",
  email = "Email",
  phone = "Phone number",
  subject = "Subject",
  message = "Message",
  source = "I am...",
}

const fields = [
  Fields.source,
  Fields.firstName,
  Fields.lastName,
  Fields.email,
  Fields.phone,
  Fields.subject,
  Fields.message,
];

type FormType = { [key in Fields]: string };
const defaultForm = fields.reduce(
  (map, field) => ({
    ...map,
    [field]: "",
  }),
  {} as FormType
);

enum Sources {
  senior = "senior",
  contactingOnBehalf = "contactingOnBehalf",
  volunteer = "volunteer",
  other = "other",
}
const sourceOptions = [
  { label: "", value: "" },
  { label: "A senior", value: Sources.senior },
  {
    label: "Writing on behalf of a senior",
    value: Sources.contactingOnBehalf,
  },
  { label: "A volunteer", value: Sources.volunteer },
  { label: "Someone else", value: Sources.other },
];

export const Contact: React.FC = () => {
  const [form, setForm] = React.useState<FormType>(defaultForm);
  const errors = React.useMemo(() => {
    const baseErrors = fields.reduce(
      (map, field) => ({
        ...map,
        [field]: !form[field] ? "Required" : "",
      }),
      {} as FormType
    );
    return {
      ...baseErrors,
      [Fields.email]:
        !!form[Fields.email] && !isValidEmail(form[Fields.email])
          ? "Enter a valid email"
          : "",
      [Fields.phone]: "",
    };
  }, [form]);
  const hasFormErrors = Object.values(errors).some(Boolean);

  const contactTemplate = useContactTemplate({
    name: `${form[Fields.firstName]} ${form[Fields.lastName]}`,
    email: form[Fields.email],
    phone: form[Fields.phone],
    subject: `[VN Website] ${form[Fields.subject]}`,
    message: form[Fields.message],
    source: sourceOptions.find(({ value }) => form[Fields.source] === value)
      ?.label,
  });

  const {
    isSubmitting,
    status,
    onSubmit,
    hasClickedSubmit,
    recaptchaComponent,
  } = useForm({
    ...contactTemplate,
    setForm,
    defaultForm,
    hasFormErrors,
    recaptchaSiteKey: "REACT_APP_RECAPTCHA_SITE_KEY_CONTACT_INVISIBLE",
  });

  const displayErrors = React.useMemo(
    () =>
      fields.reduce(
        (map, field) => ({
          ...map,
          [field]: hasClickedSubmit ? errors[field] : "",
        }),
        {} as FormType
      ),
    [errors, hasClickedSubmit]
  );

  const onFieldChange = React.useCallback(
    ({ name, value }: { name: string; value: string }) => {
      setForm({
        ...form,
        [name]: value,
      });
    },
    [setForm, form]
  );

  const sourceValue = form[Fields.source];

  return (
    <>
      <Title>Contact</Title>
      <Wrapper>
        <Form>
          <p>
            Please fill out the form below, along with your email and a detailed
            message, to request more information about any of our services.
          </p>
          <FieldWrapper>
            <SelectField
              options={sourceOptions}
              onChange={onFieldChange}
              name={Fields.source}
              value={sourceValue}
              error={displayErrors[Fields.source]}
              required
            />
            {sourceValue === Sources.senior && (
              <SourceDescription>
                We will call you directly when we receive your message
              </SourceDescription>
            )}
            {sourceValue === Sources.contactingOnBehalf && (
              <SourceDescription>
                The person you are referring must make the initial contact
                themselves
              </SourceDescription>
            )}
          </FieldWrapper>
          <InlineFieldsWrapper>
            <InputField
              onChange={onFieldChange}
              name={Fields.firstName}
              value={form[Fields.firstName]}
              error={displayErrors[Fields.firstName]}
              required
            />
            <InputField
              onChange={onFieldChange}
              name={Fields.lastName}
              value={form[Fields.lastName]}
              error={displayErrors[Fields.lastName]}
              required
            />
          </InlineFieldsWrapper>
          <InlineFieldsWrapper>
            <InputField
              onChange={onFieldChange}
              name={Fields.email}
              value={form[Fields.email]}
              error={displayErrors[Fields.email]}
            />
            <InputField
              onChange={onFieldChange}
              name={Fields.phone}
              value={form[Fields.phone]}
              error={displayErrors[Fields.phone]}
              required
            />
          </InlineFieldsWrapper>
          <FieldWrapper>
            <InputField
              onChange={onFieldChange}
              name={Fields.subject}
              value={form[Fields.subject]}
              error={displayErrors[Fields.subject]}
              required
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputField
              onChange={onFieldChange}
              name={Fields.message}
              value={form[Fields.message]}
              type="textarea"
              error={displayErrors[Fields.message]}
              rows={6}
              required
            />
          </FieldWrapper>
          <Button
            size="large"
            onClick={onSubmit}
            disabled={Object.values(displayErrors).some(Boolean)}
            loading={isSubmitting}
          >
            Submit
          </Button>
          {status}
          {recaptchaComponent}
        </Form>
        <General>
          <Subtitle>Thank you for your interest!</Subtitle>
          <p>
            Feel free to leave a message on our phones. We will get back to you
            as soon as we can.
          </p>
          <p>
            <Strong>Email</Strong>
            <Link href={`mailto:${officeEmail}`} variation="highlighted">
              {officeEmail}
            </Link>
          </p>
          <p>
            <Strong>Phone</Strong>
            <Link href={officePhoneLink} variation="highlighted">
              {officePhone}
            </Link>
          </p>
          <p>
            <Strong>Address</Strong>
            3 Washington Square Village, Suite 1F
            <br />
            New York, NY 10012
          </p>
          <p>
            <Strong>Office hours</Strong>
            Monday - Thursday
            <br />
            10:00 AM - 5:30 PM
          </p>
        </General>
      </Wrapper>
    </>
  );
};
