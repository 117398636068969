import { CSSObject } from "@emotion/styled";

type TypographyNames =
  | "accent"
  | "Header"
  | "HeaderB"
  | "Subtitle"
  | "Title"
  | "BodyM"
  | "BodyB"
  | "Body"
  | "CaptionM"
  | "CaptionB"
  | "Caption";

export type Typography = {
  [key in TypographyNames]: CSSObject;
};

export const typography: Typography = {
  accent: {
    fontFamily: '"DM Serif Text", serif',
  },
  Title: {
    fontSize: 40,
    lineHeight: "60px",
    fontFamily: '"DM Serif Text", serif',
  },
  Subtitle: {
    fontSize: 28,
    lineHeight: "40px",
    fontWeight: 800,
  },
  Header: {
    fontSize: 20,
    lineHeight: "32px",
    fontWeight: 600,
  },
  HeaderB: {
    fontSize: 20,
    lineHeight: "32px",
    fontWeight: 800,
  },
  Body: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
  },
  BodyM: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
  },
  BodyB: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 700,
  },
  Caption: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
  },
  CaptionM: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
  },
  CaptionB: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 700,
  },
};
