import * as React from "react";
import { SideBySide } from "../../components/SideBySide";
import { Title, Subtitle } from "../../components/Typography";
import Office from "../../assets/photos/office.jpg";
import Health from "../../assets/photos/health.jpg";
import { Color } from "./Color";
import styled from "@emotion/styled";

const Grid = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
});

const Description = styled.div(({ theme }) => ({
  paddingBottom: theme.spacing[16],
}));
export const AboutUs: React.FC = () => {
  return (
    <>
      <Title>About us</Title>
      <SideBySide
        imgAlt="Staff and volunteers in the office"
        imgSrc={Office}
        hasExtraSpace
      >
        <Subtitle>Mission</Subtitle>
        <p>
          Since 1972, Visiting Neighbors has been a trusted life-line, helping
          seniors in lower Manhattan as they face the challenges of aging, most
          often alone, to remain independent and safe in their own homes while
          also staying connected, informed and a vital part of the New York
          Community.
        </p>
        <p>
          Through programs of “neighbors helping neighbors,” volunteers,
          supported by Visiting Neighbors’ professional staff, help older
          adults, 60 - Centenarians plus, alleviate loneliness and isolation,
          provide mental stimulation and emotional security, share information
          about wellness and health related concerns, and encourage physical
          independence and quality of life at home.
        </p>
        <p>
          Visiting Neighbors champions the power of the volunteer and encourages
          interdependence so that mutual learning and understanding bring
          generations together to share life experiences and gain respect for
          each individual’s uniqueness.
        </p>
      </SideBySide>
      <SideBySide imgAlt="Volunteers checking blood pressure" imgSrc={Health}>
        <Subtitle>Statement of our charity</Subtitle>
        <p>
          We are providing essential and life-enriching services and we will
          continue to be on the frontlines, ready to serve our community and
          existing clients as their needs increase, especially the oldest-old
          (age 85+) who often need more attention from our staff.
        </p>
        <p>
          Our long-term goal is to help make seniors lives happier, safer, less
          lonely and frightening, address their health concerns, prepare for
          emergencies, cope with many changes and help seniors be better
          connected to others, empowered to identify and communicate their
          needs, be better informed, more social and express more joyous
          feelings.
        </p>
        <p>
          Visiting Neighbors will continue to advocate on behalf of seniors to
          be better understood, treated with compassion, dignity and respect and
          to not be ignored, overlooked, forgotten and to help foster their
          self-esteem and self-worth. Many of our clients have expressed to us -
          we are the only family they have.
        </p>
      </SideBySide>
      <Subtitle withMargin>Our colors</Subtitle>
      <Description>
        Everything we do has meaning and purpose, including the colors we use:
      </Description>
      <Grid>
        <Color color="red">Representing our heart</Color>
        <Color color="yellow">
          For the sunshine we bring into the lives of our seniors
        </Color>
        <Color color="aqua">For our "true blue" volunteers</Color>
        <Color color="gray">Representing aging</Color>
      </Grid>
    </>
  );
};
