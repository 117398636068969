import * as React from "react";
import styled from "@emotion/styled";
import { isValidEmail } from "../../../helpers/isValidEmail";
import { Button } from "../../../components/Button";
import { InputField } from "../../../components/InputField";
import { useForm } from "../../../hooks/useForm";
import { useContactTemplate } from "../../../hooks/useContactTemplate";

const Form = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  gap: 8,
  flexGrow: 1,
  maxWidth: 340,
  [theme.breakpoints.phone]: {
    maxWidth: "100%",
  },
}));

const Title = styled.div(({ theme }) => ({
  ...theme.typography.BodyM,
  paddingBottom: theme.spacing[4],

  [theme.breakpoints.phone]: {
    ...theme.typography.CaptionM,
  },
}));

const Wrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

type FormType = { email: string };
const defaultForm = { email: "" };

export const StayConnected: React.FC = () => {
  const [form, setForm] = React.useState<FormType>(defaultForm);
  const error = !isValidEmail(form.email) ? "Enter a valid email" : "";
  const onFieldChange = React.useCallback(
    ({ value }: { value: string }) => {
      setForm({ email: value });
    },
    [setForm]
  );
  const hasFormErrors = !!error;

  const contactTemplate = useContactTemplate({
    email: form.email,
    message: "I would like to stay connected",
    subject: "[VN Website] Stay connected request",
  });

  const {
    isSubmitting,
    status,
    onSubmit,
    hasClickedSubmit,
    recaptchaComponent,
  } = useForm({
    ...contactTemplate,
    setForm,
    defaultForm,
    hasFormErrors,
    recaptchaSiteKey: "REACT_APP_RECAPTCHA_SITE_KEY_CONTACT_INVISIBLE",
  });

  return (
    <Wrapper>
      <Title>Stay connected!</Title>
      <div>
        <Form>
          <InputField
            onChange={onFieldChange}
            name="Email"
            value={form.email}
            error={hasClickedSubmit ? error : ""}
            labelSize="caption"
          />
          <Button
            onClick={onSubmit}
            size="small"
            disabled={hasClickedSubmit && hasFormErrors}
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Form>
      </div>
      {status}
      {recaptchaComponent}
    </Wrapper>
  );
};
