import * as React from "react";
import styled from "@emotion/styled";

const Phone = styled.span({
  whiteSpace: "nowrap",
});

export const email = "info@visitingneighbors.org";
export const phone = <Phone>(212) 260-6200</Phone>;
export const phoneLink = "tel:1-212-260-6200";
