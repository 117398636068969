import { urls } from "../../../helpers/urls";
import { NavigationType } from "../types";

export const siteMap: NavigationType[] = [
  { href: urls.home, locations: ["top"] },
  {
    href: urls.about.main,
    locations: ["top", "bottom"],
    children: [
      { href: urls.about.main },
      { href: urls.about.history },
      { href: urls.about.impact },
      { href: urls.about.notesSeniors },
      { href: urls.about.staff },
      { href: urls.about.whoWeServe },
    ],
  },
  {
    href: urls.programs.main,
    locations: ["top", "bottom"],
    children: [
      { href: urls.programs.friendlyVisiting },
      { href: urls.programs.shopAndEscort },
      { href: urls.programs.healthAndWellness },
      { href: urls.programs.specialOccasions },
      { href: urls.programs.telephoneReassurance },
      { href: urls.programs.therapeuticWalking },
      { href: "" },
      { href: urls.programs.faqs },
      { href: urls.programs.resources },
    ],
  },
  {
    href: urls.volunteer.main,
    locations: ["top", "bottom"],
    children: [
      { href: urls.volunteer.getInvolved },
      { href: urls.volunteer.applications },
      { href: urls.volunteer.caseNotes },
      { href: urls.volunteer.faqs },
      { href: urls.volunteer.quotes },
    ],
  },
  {
    href: urls.contact,
    locations: ["top", "bottom"],
  },
  { href: urls.donate, locations: ["bottom"] },
];
