import styled from "@emotion/styled";
import { margin } from "polished";

export const Title = styled.h1(({ theme }) => ({
  ...theme.typography.Title,
  margin: 0,
  color: theme.colors.red,
  paddingBottom: theme.spacing[8],
}));

export const Subtitle = styled.h2<{ withMargin?: boolean }>(
  ({ theme, withMargin }) => ({
    ...margin(withMargin ? theme.spacing[12] : 0, 0),
    ...theme.typography.Subtitle,
    [theme.breakpoints.tabletPortrait]: {
      ...theme.typography.HeaderB,
    },
  })
);

export const Header = styled.div(({ theme }) => ({
  ...theme.typography.Header,
  [theme.breakpoints.tabletLandscape]: {
    ...theme.typography.Body,
  },
}));
