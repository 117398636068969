import * as React from "react";
import styled from "@emotion/styled";
import { File as FileIcon } from "./Icons";
import { Link } from "./Link";

const IconWrapper = styled.span(({ theme }) => ({
  paddingRight: theme.spacing[4],
  color: theme.colors.red,
}));

const Wrapper = styled.span({
  display: "inline-flex",
  alignItems: "center",
  whiteSpace: "nowrap",
});

type FileProps = {
  name: string;
  href: string;
};

export const File: React.VFC<FileProps> = ({ name, href }) => (
  <Link variation="dark" key={name} href={href} openInNewWindow>
    <Wrapper>
      <IconWrapper>
        <FileIcon size="small" />
      </IconWrapper>
      {name}
    </Wrapper>
  </Link>
);
