import * as React from "react";
import { Title } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";
import Walk from "../../assets/photos/walk1.jpg";
import { Button } from "../../components/Button";
import { urls } from "../../helpers/urls";

export const TherapeuticWalking: React.FC = () => (
  <>
    <Title>Therapeutic walking</Title>
    <SideBySide
      imgSrc={Walk}
      imgAlt="Volunteer walking with senior"
      align="top"
    >
      <p>
        Some of our more ambulatory seniors began suffering from “cabin fever”,
        weather permitting and of they felt well enough to go out and with both
        volunteer and seniors using Personal Protective Equipment (PPE) --
        donated, purchased and hand-made -- distributed by staff, we began a
        Therapeutic Walking program. Volunteers take seniors out for a walk,
        maybe a short time sitting on a park bench, and may gain some wisdom
        along the way sharing stories.
      </p>
      <p>
        <Button href={urls.contact}>Contact us</Button>
      </p>
    </SideBySide>
  </>
);
