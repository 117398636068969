import { breakpoints } from "./breakpoints";
import { typography } from "./typography";
import { styling } from "./styling";

export const defaultTheme = {
  breakpoints,
  typography,
  ...styling,
};

export type Theme = typeof defaultTheme;
