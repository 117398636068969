import * as React from "react";

type BodyScrollContextValue = {
  isLocked: boolean;
  onRequestLockBodyScroll: () => void;
  onRequestUnlockBodyScroll: () => void;
};

const BodyScrollContext = React.createContext<
  BodyScrollContextValue | undefined
>(undefined);

export const BodyScrollProvider: React.FC = ({ children }) => {
  const [
    numComponentsRequestingLockedBodyScroll,
    setNumComponentsRequestingLockedBodyScroll,
  ] = React.useState(0);

  const handleRequestLockBodyScroll = React.useCallback(() => {
    setNumComponentsRequestingLockedBodyScroll((num) => num + 1);
  }, [setNumComponentsRequestingLockedBodyScroll]);

  const handleRequestUnlockBodyScroll = React.useCallback(() => {
    setNumComponentsRequestingLockedBodyScroll((num) => Math.max(num - 1, 0));
  }, [setNumComponentsRequestingLockedBodyScroll]);

  const isLocked = numComponentsRequestingLockedBodyScroll > 0;

  React.useLayoutEffect(() => {
    const previousOverflow = document.body.style.overflow;

    if (isLocked) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      if (isLocked) {
        document.body.style.overflow = previousOverflow;
      }
    };
  }, [isLocked]);

  const bodyScrollContext = React.useMemo(() => {
    return {
      isLocked,
      onRequestLockBodyScroll: handleRequestLockBodyScroll,
      onRequestUnlockBodyScroll: handleRequestUnlockBodyScroll,
    };
  }, [handleRequestLockBodyScroll, handleRequestUnlockBodyScroll, isLocked]);

  return (
    <BodyScrollContext.Provider value={bodyScrollContext}>
      {children}
    </BodyScrollContext.Provider>
  );
};

export function useBodyScrollLock(shouldLock: boolean = true) {
  const bodyScrollContext = React.useContext(BodyScrollContext);

  if (typeof bodyScrollContext === "undefined") {
    throw new Error(
      "useBodyScrollLock must be used within a BodyScrollProvider"
    );
  }

  const { onRequestLockBodyScroll, onRequestUnlockBodyScroll } =
    bodyScrollContext;

  // Running this in a useLayoutEffect in order to lock the body
  // scroll before the item requiring it renders.
  React.useLayoutEffect(() => {
    if (shouldLock) {
      onRequestLockBodyScroll();
    }
    return () => {
      if (shouldLock) {
        onRequestUnlockBodyScroll();
      }
    };
  }, [onRequestLockBodyScroll, onRequestUnlockBodyScroll, shouldLock]);
}

export function useBodyScrollStatus() {
  const bodyScrollContext = React.useContext(BodyScrollContext);

  if (typeof bodyScrollContext === "undefined") {
    throw new Error(
      "useBodyScrollStatus must be used within a BodyScrollProvider"
    );
  }

  const { isLocked } = bodyScrollContext;
  return { isLocked };
}
