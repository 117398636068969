import Arlene from "../../../assets/seniorNotes/Arlene.jpg";
import Evelyn from "../../../assets/seniorNotes/Evelyn.jpg";
import Margaret from "../../../assets/seniorNotes/Margaret.jpg";
import Ruth from "../../../assets/seniorNotes/Ruth.jpg";
import Sydelle from "../../../assets/seniorNotes/Sydelle.jpg";
import Blessings from "../../../assets/seniorNotes/Blessings.jpg";
import Valentine from "../../../assets/seniorNotes/Valentine.jpg";
import Smile from "../../../assets/seniorNotes/Smile.jpg";
import Marilyn from "../../../assets/seniorNotes/Marilyn.jpg";
import Marge from "../../../assets/seniorNotes/Sydelle.jpg";
import Florence from "../../../assets/seniorNotes/Florence.jpg";
import Aldona from "../../../assets/seniorNotes/aldona.jpg";
import Olga from "../../../assets/seniorNotes/olga.jpg";
import Viviana from "../../../assets/seniorNotes/Viviana.jpg";
import Marilyn2 from "../../../assets/seniorNotes/Marilyn2.jpg";
import Barbara from "../../../assets/seniorNotes/Barbara.jpg";
import Genny from "../../../assets/seniorNotes/Genny.jpg";
import Susan from "../../../assets/seniorNotes/Susan.jpg";
import ValentineAnimated from "../../../assets/seniorNotes/valentines.gif";

type Note = {
  src: string;
  alt: string;
  contents: React.ReactNode;
};
export const notes: Note[] = [
  {
    src: Smile,
    alt: "",
    contents: (
      <>
        <p>June 2022</p>
        <p>Dear Steve + Howie + Volunteers</p>
        <p>
          Thank you for all you do to make old folks like me happy. Reaching the
          age of 100 years may <u>sound</u> incredible – but living it is not!!
        </p>
        <p>
          It comforts me to know you are there. Steve, I thought this picture, a
          scene from “the good old days" (ho ho ho!) would make you smile.
        </p>
        <p>
          I send you <u>ALL</u> my love may peace and happiness be yours.
        </p>
      </>
    ),
  },
  {
    src: Blessings,
    alt: "",
    contents: (
      <>
        <p>March 2022</p>
        <p>To Whom It May Concern</p>
        <p>Re: Visiting Neighbors</p>
        <p>
          Visiting Neighbors is more than a service for shut-ins and people with
          limited mobility: it’s a lifeline. The efficiently-run organization
          could be a model for other nonprofits.
        </p>
        <p>
          The screening process is meticulous, and Steve and Howie are a stupid,
          casting directors.
        </p>
        <p>
          The alchemy of pairing people who “fit“ together is magical: each
          party is a beneficiary. The VN constituency, spans generations, and
          geography. The age to have knowledge to share, while the young bring
          their perceptions of today.
        </p>
        <p>
          It’s more than handholding to comfort those heading to medical
          appointments, it’s providing the thread that connects sometimes
          marginalized people to the community. I count VN among my blessings.
          It puts sunshine back in my life, for which I am profoundly grateful.
        </p>
        <p>Thank you.</p>
      </>
    ),
  },
  {
    src: Marge,
    alt: "Letter from Marge",
    contents: (
      <>
        <p>January 2022</p>
        <p>Dear Cynthia, Steve, Howie, and Mary,</p>
        <p>
          Thank you so much for the gift bag with all the goodies. The new masks
          will certainly come in handy during this time of Covid and new
          toothbrush is always handy. The tea bags will be soothing once made
          into hot tea on these cold winter nights, the hard candies are
          refreshing, and I love the scarf! As a beginning knitter, I appreciate
          the perfect stitches and the softness of the yarn. The color is
          beautiful (my favorite) and I love the feel of the scarf around my
          neck.
        </p>
        <p>
          Hope you had a happy holidays and warmest wishes for a healthy
          rewarding new year.
        </p>
        <p>With thanks and appreciation,</p>
        <p>Marge.</p>
        <p>
          PS the young ladies, who delivered the gift bag for a lovely and
          gracious. They brought a smile to my face. Thank them for their
          efforts!
        </p>
      </>
    ),
  },
  {
    src: Marilyn,
    alt: "Letter from Marilyn",
    contents: (
      <>
        <p>December 2021</p>
        <p>Dear Visiting Neighbors,</p>
        <p>
          Thank you for all that you do for me. I am grateful to you and always
          will be for improving the quality of my life and for knowing the
          wonderful compassionate people that you all are.
        </p>
        <p>
          May your Christmas (and all holidays) sparkle with love and after and
          good will and in the New Year!
        </p>
        <p>Love,</p>
        <p>Marilyn</p>
      </>
    ),
  },
  {
    src: Valentine,
    alt: "",
    contents: (
      <>
        <p>February 2022</p>
        <p>A belated "Happy Valentine" to one and all!!!</p>
        <p>To: Members of the "Visiting Neighbors, Inc"</p>
        <p>
          Just want you to know that your kind messages bring much <u>joy</u> &{" "}
          <u>warmth</u> to the heart of this 87-year-old lady who lives alone.{" "}
          <u>Thank you for remembering</u>... I wish I could do something for
          you or a needy someone in return!!.
        </p>
        <p>
          Meanwhile, may you all enjoy a fruitful, healthy, safe, and rewarding
          2022!
        </p>
        <p>From a grateful friend.</p>
      </>
    ),
  },
  {
    src: Margaret,
    alt: "Letter from Margaret",
    contents: (
      <>
        <p>January 2021</p>
        <p>To: Cynthia, Steve, Howie + Mary</p>
        <p>
          Dears -
          <br />
          You made my day!! What a wonderful surprise to receive all those
          goodies.
        </p>
        <p>
          The scarf is beautiful and so welcome in this weather. Thank you,
          thank you. I'm a coffee lover, especially for my morning coffee, so my
          thanks for indulging me. I'm also a sneezer, so have to have lots of
          tissues on hand. Thanks for adding to my supply -- and my supply of
          face masks. Which I always wear going out - can always use extra ? and
          an extra supply of hand santanitizer. And you indulge me as a
          cookie-eater with those delicious cookies.
        </p>
        <p>
          This is a beautiful gift and I'm thrilled to receive it. As I
          mentioned, after I returned from this rehab hospital after my fall and
          fractured my leg, I've had an aide with me. At my age -- 103 & 1/2 --
          I can no longer live alone. So that is why I haven't been calling you.
          But always remember with appreciation all your kind help.
        </p>
        <p>Best New Years wishes to all</p>
        <p>
          Fondly
          <br />
          Margaret, 103 & 1/2
        </p>
      </>
    ),
  },
  {
    src: Sydelle,
    alt: "Letter from Sydelle",
    contents: (
      <>
        <p>September 2020</p>
        <p>
          To Visiting Neighbors
          <br />
          New York, NY
          <br />
          c/o "Funders"
        </p>
        <p>
          This volunteer organization has been a life-saver for me. I am 92
          years of age, have difficulty getting around, especially when I need
          to see a doctor.
        </p>
        <p>
          These men and young women have assisted me a number of times. They are
          reliable, so supportive and wish I could afford to pay for the
          service, but I live on a fixed income. These volunteers are so
          appreciated.
        </p>
        <p>
          In gratitude,
          <br />
          (Mrs.) Sydelle, 92
        </p>
      </>
    ),
  },
  {
    src: Ruth,
    alt: "Letter from Ruth",
    contents: (
      <>
        <p>March 2019</p>
        <p>Dear Howie, Steve and Staff,</p>
        <p>
          I am grateful for the great volunteer services you provide. The
          'Visiting Neighbors' are special; they are so patient and careful.
          Those I've had the pleasure of meeting are helpful and good company.
        </p>
        <p>
          Thank you,
          <br />
          Ruth, 99
        </p>
      </>
    ),
  },
  {
    src: Arlene,
    alt: "Letter from Arlene",
    contents: (
      <>
        Dear Visiting Neighbors,
        <p>
          Thank you for all that you do for me. I am so grateful for your help.
        </p>
        <p>
          Love,
          <br />
          Arlene, 88
        </p>
      </>
    ),
  },
  {
    src: Florence,
    alt: "Letter from Florence",
    contents: (
      <>
        <p>Dear Visiting Neighbors</p>
        <p>Thanks for caring</p>
        <p>Florence S., 102</p>
      </>
    ),
  },
  {
    alt: "Letter from Evelyn",
    src: Evelyn,
    contents: (
      <>
        To whom it may concern,
        <p>
          I am writing to let you know how much I appreciate your program. I am
          a senior person who is pretty much homebound. I am alone and when I
          have appointments with various physicians, I need to have a volunteer
          assist me.
        </p>
        <p>
          I use a walker and need assistance crossing streets, climing stairs,
          and opening heavy doors.
        </p>
        <p>
          I depend on Visiting Neighbors and their wonderful, dedicated
          volunteers. Without their help I would be unable to receive needed
          medical care.
        </p>
        <p>
          Visiting Neighbors provides a much needed service to the community.
        </p>
        <p>
          With the changing political climate, I am hoping that your program
          will continue to receive much needed support.
        </p>
        <p>
          Very warmly yours,
          <br />
          Evelyn, 100
        </p>
      </>
    ),
  },
  {
    src: Aldona,
    alt: "Letter from Aldona",
    contents: (
      <>
        <p>February 2023</p>
        <p>Cynthia, Steve, Howie, and Mary --</p>
        <p>
          Thank you ALL for your kindness and always warm words -- all the best
          to you all!
        </p>
        <p>Aldona</p>
        <p>
          Knowing that you around always willing to help -- it is priceless.
        </p>
        <p>Thank you</p>
        <p>A</p>
        <p>
          Hope you know the reason why you're thought about so much...
          <br />
          Is just that every thought of you
          <br />
          Is warmed by friendship's touch!
        </p>
      </>
    ),
  },
  {
    src: Olga,
    alt: "Letter from Olga",
    contents: (
      <>
        <p>Feb 18 2021</p>
        <p>To the youngsters of Visiting Neighbors,</p>
        <p>Thank you for the lovey card you made for me.</p>
        <p>
          Happy Valentine's Day to all for your kind thought to me. In this time
          of need, is good to have some one think of me. Love the card with all
          the hearts on it. Nice work you did for me.
        </p>
        <p>Olga</p>
        <p>Be Happy you all. You are so kind. Love it so much.</p>
      </>
    ),
  },
  {
    src: Marilyn2,
    alt: "Letter from Marilyn",
    contents: (
      <>
        <p>December 2023</p>
        <p>Dear Visiting Neighbors, </p>
        <p>
          I am truly grateful for the work that you do. Without your services I
          would never have the quality of life which brings me joy and peace of
          mind. Thank you.
        </p>
        <p>May your Christmas sparkle with love, laughter, and goodwill</p>
        <p>Sincerely,</p>
        <p>Marilyn</p>
      </>
    ),
  },
  {
    src: Barbara,
    alt: "Letter from Barbara",
    contents: (
      <>
        <p>January 2024</p>
        <p>Dear Visiting Neighbors,</p>
        <p>
          Thank you so much for elf gifts. I love the Norman Rockwell calendar
          especially and the woolen scarf, what a labor of love! And I enjoyed
          seeing my dear friend, elf Ray, too! And the meals on heels -- I am
          speechless!!! Thank you again, wishing you all a wonderful 2024!
        </p>
        <p>Wishing you happiness and joy this festive season</p>
        <p>Barbara</p>
      </>
    ),
  },
  {
    src: Viviana,
    alt: "Letter from Viviana",
    contents: (
      <>
        <p>February 2024</p>
        <p>Cynthia, Steve, Howie, and Mary</p>
        <p>Visiting Neighbors</p>
        <p>
          Thank you for the Holiday Gift Bag and the Services. I will be 103 in
          February 2024. I thank you for helping Seniors.
        </p>
        <p>Sincerely,</p>
        <p>Viviana</p>
      </>
    ),
  },
  {
    src: ValentineAnimated,
    alt: "",
    contents: (
      <>
        <p>
          <b>To:</b> info@visitingneighbors.org
          <br />
          <b>Date:</b> 02/12/2024 11:03 AM EST
          <br />
          <b>Subject:</b> Thank you!
        </p>

        <p>
          <b style={{ color: "red" }}>LOVE LOVE LOVE</b> my{" "}
          <b style={{ color: "red", fontSize: "large" }}>
            Valentine’s Day card
          </b>
          ! It’s simply <b style={{ color: "blue" }}>WONDERFUL</b>!
        </p>

        <p>
          Please thank <b style={{ color: "green", fontSize: "large" }}>NORA</b>{" "}
          for her FABULOUS card. The design is SO great, as well as the
          sentiments.
        </p>

        <p>Made me happy!</p>

        <p>
          Please wish <b style={{ color: "green", fontSize: "large" }}>NORA</b>{" "}
          a <b style={{ color: "red", fontSize: "large" }}>Happy Valentine’s</b>{" "}
          and thank her for her GREAT card!
        </p>

        <p>
          <b>
            Wishing everyone at Visiting Neighbors a Happy Valentine’s Day, too!
          </b>
        </p>
        <p>🥰</p>
        <p>Eliane</p>
      </>
    ),
  },
  {
    src: Genny,
    alt: "Note from Genny",
    contents: (
      <>
        <p>2/18/2024</p>
        <p>Dear Boys & Girls</p>
        <p>
          Thank you for the beautiful Valentines card you sent me. It is the
          only one I got! So it was very special and made me smile.
        </p>
        <p>Genny</p>
      </>
    ),
  },
  {
    src: Susan,
    alt: "Note from Susan",
    contents: (
      <>
        <p>Feb 18, 2024</p>
        <p>Dear Children,</p>
        <p>
          I received a lovely Valentine's Day card from one of you today. I want
          to thank you for it because if was a very pleasant surprise that
          brought a big smile to my face. I loved the beautiful art work on the
          card and wish the artist of my card and all the other children a very
          happy Valentine's Day!
        </p>
        <p>Love,</p>
        <p>Susan</p>
      </>
    ),
  },
];
