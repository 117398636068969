import { useCallback, useState } from "react";

export const useToggle = (initial = false) => {
  const [on, setOn] = useState(initial);
  const turnOn = useCallback(() => setOn(true), []);
  const turnOff = useCallback(() => setOn(false), []);
  const reset = useCallback(() => setOn(initial), [initial]);
  const toggle = useCallback(() => setOn((prev) => !prev), []);

  return {
    on,
    turnOn,
    turnOff,
    reset,
    toggle,
  };
};
