import * as React from "react";
import styled from "@emotion/styled";
import { border, padding } from "polished";
import { Caret } from "../../../components/Icons";

type MobileNavigationItemProps = {
  href: string;
  variation: "primary" | "secondary";
};

const Wrapper = styled.button<Pick<MobileNavigationItemProps, "variation">>(
  ({ theme, variation }) => ({
    ...theme.typography.CaptionM,
    border: "none",
    cursor: "pointer",
    ...border("top", 1, "solid", theme.colors.gray100),
    ...padding(
      theme.spacing[12],
      {
        primary: theme.spacing[16],
        secondary: theme.spacing[32],
      }[variation],
      theme.spacing[12],
      {
        primary: theme.spacing[20],
        secondary: theme.spacing[36],
      }[variation]
    ),
    width: "100%",
    flexGrow: 1,
    margin: 0,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: {
      primary: theme.colors.black,
      secondary: theme.colors.gray300,
    }[variation],
    backgroundColor: {
      primary: theme.colors.white,
      secondary: theme.colors.gray,
    }[variation],
    "&:focus": {
      outline: "none",
      color: theme.colors.red,
    },
    "&:focus > *": {
      color: theme.colors.red,
    },
  })
);

const CaretWrapper = styled.div(({ theme }) => ({
  paddingLeft: theme.spacing[24],
  color: theme.colors.gray200,
}));

export const MobileNavigationItem: React.FC<MobileNavigationItemProps> = ({
  href,
  variation,
  children,
}) => {
  const onClick = React.useCallback(() => {
    window.location = href as any;
  }, [href]);
  return (
    <Wrapper variation={variation} onClick={onClick}>
      {children}
      {variation === "primary" ? (
        <CaretWrapper aria-hidden="true">
          <Caret size="small" />
        </CaretWrapper>
      ) : null}
    </Wrapper>
  );
};
