import * as React from "react";
import { Title, Subtitle } from "../components/Typography";
import { urls } from "../helpers/urls";
import styled from "@emotion/styled";
import { border, padding } from "polished";
import { Link } from "../components/Link";
import { Lock } from "../components/Icons";
import { Spinner } from "../components/Spinner";

const Donorbox = styled.iframe(({ theme }) => ({
  maxWidth: 500,
  minWidth: 250,
  maxHeight: "none !important",
  width: "100%",
  height: 900,
  zIndex: theme.zIndex.bump,
}));

const DonorboxContainer = styled.div({
  position: "relative",
});

const SpinnerWrapper = styled.div(({ theme }) => ({
  color: theme.colors.red,
  position: "absolute",
  top: theme.spacing[16],
  left: theme.spacing[16],
}));

const DonorboxWrapper = styled.div({
  flexShrink: 0,
  maxWidth: 500,
  minWidth: 250,
});

const DonorboxSecurity = styled.div(({ theme }) => ({
  textAlign: "left",
  paddingBottom: theme.spacing[12],
}));

const Icon = styled.div(({ theme }) => ({
  ...padding(0, theme.spacing[4], theme.spacing[4], 0),
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
}));

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  justifyItems: "center",
  [theme.breakpoints.tabletPortrait]: {
    flexDirection: "column",
  },
}));

const Content = styled.div(({ theme }) => ({
  ...border("right", 1, "solid", theme.colors.gray),
  paddingRight: theme.spacing[40],
  marginRight: theme.spacing[40],
  [theme.breakpoints.tabletPortrait]: {
    border: "none",
    ...border("bottom", 1, "solid", theme.colors.gray),
    padding: 0,
    margin: 0,
    marginBottom: theme.spacing[24],
  },
}));

export const Donate: React.FC = () => {
  return (
    <>
      <Title>Donate</Title>
      <Wrapper>
        <Content>
          <p>
            There are many ways to support Visiting Neighbors and our programs.
            Every contribution goes towards helping seniors.
          </p>
          <Subtitle>In-kind donations</Subtitle>
          <p>
            We gladly accept other kinds of donations besides much needed $$ to
            help our important cause:
          </p>
          <p>
            <b>Pro bono assistance</b>
            <br />
            Do you have a skill or service to share? This could include
            development/grant writing, artistic talent (visual & literary),
            event planning, legal, advocacy, printing, etc.
          </p>
          <p>
            <b>Gifts of value</b>
            <br /> This could include art, jewelry, stocks, etc.
          </p>
          <p>
            <b>Seasonal items for seniors' goody bags</b>
            <br />
            These are distributed during the year end holidays. This could
            include toiletries, Stationary items, calendars, PPE supplies, etc.
          </p>
          <p>
            <b>Time</b>
            <br />
            Are you interested in participating on a committee?
          </p>
          <p>
            <b>Planned giving/bequests</b>
            <br />
            Consider remembering Visiting Neighbors in your will.
          </p>
          <p>
            <b>Corporate gifts</b>
            <br />
            Does your company provide corporate gifts and/or services to
            non-profits? Would they provide matching funding to your
            contribution?
          </p>
        </Content>
        <DonorboxWrapper>
          <DonorboxSecurity>
            <Icon>
              <Lock size="small" />
            </Icon>
            We use{" "}
            <Link variation="highlighted" href={urls.donorbox}>
              Donorbox
            </Link>
            , a secure way to make donations:
          </DonorboxSecurity>
          <DonorboxContainer>
            <SpinnerWrapper>
              <Spinner size="large" />
            </SpinnerWrapper>
            <Donorbox
              title="donorbox"
              {...{
                allowPaymentRequest: "",
                frameBorder: 0,
                seamless: true,
              }}
              name="donorbox"
              scrolling="no"
              src="https://donorbox.org/embed/visiting-neighbors"
            />
          </DonorboxContainer>
        </DonorboxWrapper>
      </Wrapper>
    </>
  );
};
