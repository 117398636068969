import * as React from "react";
import styled from "@emotion/styled";
import { padding, margin } from "polished";
import { defaultTheme, Theme } from "../helpers/theming";
import { Spinner } from "./Spinner";
import { useHistory } from "react-router";

const buttonSizeToValue = {
  small: 28,
  medium: 40,
  large: 48,
};

type ButtonProps = {
  type?: React.ComponentProps<"button">["type"];
  size?: "small" | "medium" | "large";
  variation?: "primary" | "secondary";
  styling?: "normal" | "accent";
  children: React.ReactNode;
  onClick?: (event?: React.SyntheticEvent) => void;
  loading?: boolean;
  disabled?: boolean;
  href?: string;
};

const colors = {
  primary: defaultTheme.colors.white,
  secondary: defaultTheme.colors.black,
};

const backgroundColors = {
  primary: defaultTheme.colors.red,
  secondary: defaultTheme.colors.yellow,
};

const minWidths = {
  large: 160,
  medium: 100,
  small: undefined,
};

const fontSizes = {
  large: defaultTheme.typography.Header,
  medium: defaultTheme.typography.BodyM,
  small: defaultTheme.typography.CaptionM,
};

const buttonStyles = ({
  disabled,
  size = "medium",
  variation = "primary",
  theme,
  loading,
  styling,
}: ButtonProps & { theme: Theme }) => [
  {
    ...fontSizes[size],
    ...padding(0, size === "small" ? theme.spacing[12] : theme.spacing[16]),
    ...margin(0),
    ...(styling === "accent" ? theme.typography.accent : {}),
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderRadius: buttonSizeToValue[size] / 2,
    color: colors[variation],
    backgroundColor: backgroundColors[variation],
    height: buttonSizeToValue[size],
    minWidth: minWidths[size],
    textDecoration: "none",
    "&:focus": {
      boxShadow: `0 0 0 2px ${theme.colors.white}, 0 0 0 4px ${backgroundColors[variation]}`,
    },
    "&:focus-visible": {
      outline: `2px solid ${backgroundColors[variation]}`,
      outlineOffset: theme.spacing[2],
      boxShadow: "none",
    },
    "&:focus:not(:focus-visible)": {
      outline: "none",
    },
  },
  disabled && {
    cursor: "not-allowed",
    color: theme.colors.gray200,
    backgroundColor: theme.colors.gray100,
  },
  loading && {
    cursor: "not-allowed",
  },
];

const StyledLink = styled.a<ButtonProps>(buttonStyles as any);

const StyledButton = styled.button<ButtonProps>(buttonStyles as any);

export const Button: React.FC<ButtonProps> = ({
  disabled,
  loading,
  children,
  type = "button",
  href,
  ...rest
}) => {
  const isExternal =
    !href || href.indexOf("http://") === 0 || href.indexOf("https://") === 0;
  const isAsset = href && href.indexOf("assets/") > -1;
  const anchorProps = isExternal
    ? {
        rel: "noopener noreferrer",
        target: "_blank",
      }
    : {};
  const tabIndex = disabled ? { tabIndex: -1 } : { tabIndex: 0 };

  const history = useHistory();
  const handleInternalAnchorClick = React.useCallback(
    (event) => {
      event.preventDefault();
      history.push(href ?? "");
    },
    [href, history]
  );

  if (href) {
    return (
      <StyledLink
        {...anchorProps}
        {...tabIndex}
        {...rest}
        loading={loading}
        href={href}
        type={type}
        disabled={disabled || loading}
        onClick={
          !isAsset && !isExternal ? handleInternalAnchorClick : undefined
        }
      >
        {loading ? <Spinner size="small" /> : children}
      </StyledLink>
    );
  }

  return (
    <StyledButton {...rest} disabled={disabled} loading={loading} type={type}>
      {loading ? <Spinner size="small" /> : children}
    </StyledButton>
  );
};
